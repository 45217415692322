import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ModalAlert({ open, setOpen }: { open: boolean; setOpen: () => void }) {
    const { t } = useTranslation();
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("deleteportfolio")}
                </Dialog.Title>
                <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={() => setOpen()} />
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500">
                        {t("deletegallery_d")}
                    </p>
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full bg-orange-500 text-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                        onClick={() => {
                            return "save"
                        }}
                    >
                        {t("keep")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full bg-white border px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => {
                            return "nosave"
                        }}
                    >
                        {t("delete")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
