import { faCcMastercard, faCcVisa, faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CardMe from "../../components/Box/CardMe";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButton";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import OrderAPI from "../../services/API/Clients/OrderAPI";
import { Storage } from "../../services/storage";
import CardSide from "./components/CardSide";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";

import PostFinance from "../../assets/images/paiement/postFinance.webp"
import PostFinanceEFinance from "../../assets/images/paiement/postFinanceEFinance.webp"
import Twint from "../../assets/images/paiement/twintLogo.png"
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import { handleError } from "../../services/Errors/handleErrors";

export default function CartTransaction() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;
    const navigate = useNavigate()
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [cgvRead, setCgvRead] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);


    const items = Storage.getCart();
    const itemsOneShot = Storage.getToBuy()

    const query = new URLSearchParams(useLocation().search);
    const pageQuery = query.get('from');


    const proceedToPaiement = async () => {
        let data: { pictures: string[] } = { pictures: []}
        if (pageQuery) {
            if (itemsOneShot && itemsOneShot.id !== undefined)
                data.pictures = [`/api/public/pictures/${itemsOneShot.id}`]
            else
                return;
            const response = await OrderAPI.orders(data);
            if (response.status === 200 || response.status === 201) {
                const dataBis = {
                    pictureOrder: `/api/orders/${response.body.id}`
                }
                if (response?.body?.totalPrice === 0.0) {
                    navigate("/checkout-success/order")
                    return;
                }
                const resp = await OrderAPI.orderTransaction(dataBis);
                if (resp.status === 200 || resp.status === 201) {
                    window.open(resp.body.redirectUrl, "_blank", "noreferrer");
                } else {
                    handleError(resp);
                }
            } else {
                handleError(response);
            }
        } else {
            if (items && items.length > 0 )
                data.pictures = items.map((item) => `/api/public/pictures/${item.id}`)
            else
                return
            const response = await OrderAPI.orders(data);
            if (response.status === 200 || response.status === 201) {
                const dataBis = {
                    pictureOrder: `/api/orders/${response.body.id}`
                }
                if (response?.body?.totalPrice === 0.0) {
                    navigate("/checkout-success/order")
                    return;
                }
                const resp = await OrderAPI.orderTransaction(dataBis);
                if (resp.status === 200 || resp.status === 201) {
                    window.open(resp.body.redirectUrl, "_blank", "noreferrer");
                } else {
                    handleError(resp);
                }
            } else {
                handleError(response);
            }
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <>
            <Loader loading={loading} />
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4  pb-[340px] md:pb-36 min-h-screen">
                    <motion.div
                        variants={{
                            visible: { top: "48px", paddingTop: "24px" },
                            hidden: { top: "0px", paddingTop: "6px" },
                            barsInactive: { top: "48px", paddingTop: "22px" },
                            barsActive: { top: "102px", paddingTop: "18px" },
                        }}
                        animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                        transition={{
                            duration: 0.35,
                            ease: "easeInOut",
                        }}
                        className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200"
                    >
                        <TitlePage mt={0} mb={0}>
                            {t("myorder")}
                        </TitlePage>
                    </motion.div>
                    <WrapperGrayZone pt="0" px="8">
                        <div className="flex flex-col h-full pb-[390px] md:pb-40">
                            <div className="flex flex-col md:flex-row px-2 md:px-8 w-full h-full max-w-7xl mx-auto gap-6">
                                <div className="pt-4 md:pt-0 w-full md:w-1/2 min-h-full flex items-center">
                                    <CardSide />
                                </div>
                                <div className="w-full md:w-1/2 bg-gray-50 min-h-full flex justify-center items-center">
                                    <div className="flex flex-col gap-4 items-center justify-center">
                                        <h1 className="text-xl mt-8">{t("payementdata")}</h1>
                                        <CardMe />
                                        <Checkbox
                                            color="orange"
                                            checked={cgvRead}
                                            onChange={(e) => setCgvRead(e.target.checked)}
                                            label={<div className="" dangerouslySetInnerHTML={{ __html: t("cgvRead") }}></div>}
                                        />
                                        <button className="w-fit mx-auto" onClick={() => proceedToPaiement()}>
                                            <GradientButton text={t("proceed")} disabled={(!items && !itemsOneShot) || !cgvRead} />
                                        </button>
                                        <div className="flex flex-row gap-4 mx-auto text-3xl text-gray-400">
                                            <FontAwesomeIcon icon={faCcVisa} className="text-blue-900" />
                                            <FontAwesomeIcon icon={faCcMastercard} className="text-red-600" />
                                            <img src={PostFinance} className="h-7 rounded" />
                                            <img src={PostFinanceEFinance} className="h-7 rounded" />
                                            <img src={Twint} className="h-7 rounded" />
                                            <FontAwesomeIcon icon={faCcPaypal} className="text-blue-900" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
        // <div className="min-h-screen bg-gray-50 relative">
        //     <Loader loading={loading} />
        //     <HeaderForAnimation />
        //     <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
        //     <Footer />
        // </div>
    );
}
