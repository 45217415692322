import React, { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterStoreInterface } from "../../../services/redux/reducers/filtersReducer";
import { Galleries } from "../../Interfaces/PortfolioType";
import {
  CompatibleFilterList,
  FetchFiltersFunction,
  FetchResultFunction,
  FilterList,
  FilterRenderConfiguration,
  FilterSchema,
  SortOption,
} from "../../SearchResult/types/global";
import { FilterConfiguration } from "../filters/FilterConfiguration";
import { useNavigate } from "react-router-dom";

interface FilterContextProps<
  FiltersType extends FilterList,
  Entity,
  CompatibleFiltersType extends CompatibleFilterList
> {
  filtersSchema: FilterSchema<FiltersType>;
  hideFilters: string[];
  filtersRender: FilterRenderConfiguration<CompatibleFiltersType>;
  fetchResults: FetchResultFunction<FiltersType, Entity>;
  fetchGalleries?: FetchResultFunction<FiltersType, Galleries>;
  fetchFilters: FetchFiltersFunction<FiltersType, CompatibleFiltersType>;
  renderEntity?: (
    entity: Entity,
    index: number,
    context: Entity[],
    loadMore: () => void
  ) => JSX.Element;
  renderParent?: (entities: Entity[], loadMore: () => void) => JSX.Element;
  onInit?: (loadMoreMethod: () => void) => void;
  onNewResults?: (results: Entity[]) => void;
  NoResults: JSX.Element;
  filterOverrides?: FiltersType;
  sortOptions: SortOption[];
  defaultSort?: string;
  filters: boolean;
  reloadDate?: Date;
}

interface FilterContextInterface<
  FiltersType extends FilterList,
  Entity,
  CompatibleFiltersType extends CompatibleFilterList
> extends FilterContextProps<FiltersType, Entity, CompatibleFiltersType> {
  filterStore: FilterStoreInterface;
  filterConfiguration: FilterConfiguration<FiltersType>;
}

const FilterContext = createContext<
  FilterContextInterface<FilterList, any, CompatibleFilterList> | undefined
>(undefined);

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilters must be used within a FilterProvider");
  }
  return context;
};

interface FilterProviderProps<
  FiltersType extends FilterList,
  Entity,
  CompatibleFiltersType extends CompatibleFilterList
> extends FilterContextProps<FiltersType, Entity, CompatibleFiltersType> {
  children: React.ReactNode | React.ReactNode[];
}

export function FilterProvider<
  FiltersType extends FilterList,
  Entity,
  CompatibleFiltersType extends CompatibleFilterList
>({
  children,
  fetchFilters,
  fetchResults,
  fetchGalleries,
  filtersRender,
  filtersSchema,
  sortOptions,
  defaultSort,
  filters,
  renderEntity,
  renderParent,
  onInit,
  onNewResults,
  NoResults,
  filterOverrides,
  hideFilters,
  reloadDate,
}: Readonly<FilterProviderProps<FiltersType, Entity, CompatibleFiltersType>>) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterStore: FilterStoreInterface = useSelector(
    (state: any) => state.filters
  );

  const filterConfiguration = new FilterConfiguration(
    navigate,
    filterStore,
    dispatch,
    filterOverrides,
    defaultSort
  );

  const providerValue = useMemo(
    () => ({
      filterStore,
      filterConfiguration,
      fetchFilters: fetchFilters as any,
      fetchResults: fetchResults as any,
      fetchGalleries: fetchGalleries as any,
      filtersRender: filtersRender as any,
      filtersSchema,
      sortOptions,
      defaultSort,
      filters,
      renderEntity,
      renderParent,
      onInit,
      onNewResults,
      NoResults,
      filterOverrides,
      hideFilters,
      reloadDate
    }),
    [
      filterStore,
      filterConfiguration,
      fetchFilters,
      fetchResults,
      fetchGalleries,
      filtersRender,
      filtersSchema,
      sortOptions,
      defaultSort,
      filters,
      renderEntity,
      renderParent,
      onInit,
      onNewResults,
      NoResults,
      filterOverrides,
      hideFilters,
      reloadDate
    ]
  );

  return (
    <FilterContext.Provider value={providerValue}>
      {children}
    </FilterContext.Provider>
  );
}
