import { Checkbox, Chip, Spinner, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import InputFileButton from "../../components/Form/Input/InputGradient";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { Storage } from "../../services/storage";
import { DateChunk } from "./DateChunk";
import SideUpload from "./components/SideUpload";
import { DEFAULT_PICTURE, PelliclePicture, UploadedPicturesChunk, addPictureToChunks, picturesToChunks } from "./pictureChunks";
import { handleError } from "../../services/Errors/handleErrors";

interface UploadState {
    pictures: UploadedPicturesChunk[];
    loading: boolean;
    deleting: boolean;
    lateralOpen: boolean;
    selected: string[];
    defaultData?: {
        place?: {
            id?: string,
            label?: string
        }
    }
}

const DEFAULT_STATE: UploadState = {
    pictures: [],
    loading: true,
    deleting: false,
    lateralOpen: false,
    selected: [],
    defaultData: {
        place: {
            id: "",
            label: ""
        }
    }
};

export default function Upload() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("pellicle")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [numberImported, setNumberImported] = useState(0);
    const { acceptedFiles, inputRef, getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/jpeg": [],
            "image/png": [],
        },
        noClick: true,
    });

    const [state, setState] = useState<UploadState>(DEFAULT_STATE);

    const updateState = (newState: (prevState: UploadState) => Partial<UploadState>) => {
        setState((prevState) => ({
            ...prevState,
            ...newState(prevState),
        }));
    }

    const updatePicture = (id: string, newPicture: Partial<PelliclePicture>) => {
        updateState(prev => ({
            pictures: prev.pictures.map((chunk) => ({
                ...chunk,
                pictures: chunk.pictures.map((picture) => picture.id === id ? { ...picture, ...newPicture } : picture),
            })),
            selected: prev.selected.map((selectedId) => newPicture.id && selectedId === id ? newPicture.id : selectedId),
        }));
    }

    const getPicture = (id: string): PelliclePicture | null => {
        for (const chunk of state.pictures) {
            const picture = chunk.pictures.find((picture) => picture.id === id);
            if (picture) {
                return picture;
            }
        }
        return null;
    }

    const fetchPictures = async () => {
        const myId = Storage.getId();
        if (myId === null) return;
        const response = await PicturesAPI.pellicle(myId);
        if (response.status === 200) {
            const pictures = picturesToChunks(response.body);
            updateState(_ => ({
                pictures,
                loading: false,
            }));
        } else {
            handleError(response);
        }
        
        const resp = await UserAPI.getWeeklyUploads(myId)
        if (resp.status === 200) {
            setNumberImported(resp.body);
        } else {
            handleError(resp);
        }
    }

    useEffect(() => {
        fetchPictures();
        updateState(_ => ({
            loading: true,
        }));
    }, []);

    const createPlaceholderPictures = (placeholders: string[]): PelliclePicture[] => {
        return placeholders.map((placeholder) => ({
            ...DEFAULT_PICTURE,
            id: `temp_${new Date().getTime() + Math.random() * 1000}`,
            minPath: placeholder,
            placeholder: true,
            uploadedAt: new Date(),
        }));
    }

    const addPictures = async (pictures: PelliclePicture[]) => {
        updateState(prev => {
            const chunk = [...prev.pictures.map(c => ({ ...c, pictures: [...c.pictures] }))];
            pictures.forEach(picture => {
                addPictureToChunks(chunk, picture);
            });
            return {
                pictures: chunk,
            }
        });
    }

    const attachPlaceholderToPicture = (placeholderId: string, id: string) => {
        updatePicture(placeholderId, {
            id,
            placeholder: false,
        });
    }

    const handleUpload = async (files: File[]) => {
        if (files.length === 0)
            return

        const fileArray = Array.from(files)

        const placeholders = createPlaceholderPictures(fileArray.map((file) => URL.createObjectURL(file)));

        Promise.all(fileArray.map(async (file, i) => {
            const data = new FormData();
            data.append("image", file);

            const placeholderId = placeholders[i].id;

            try {
                const response = await PicturesAPI.uploadPicture(data);
                if (response.status === 201) {
                    getPicturesUploadThisWeek()
                    attachPlaceholderToPicture(placeholderId, response.body.id);
                } else {
                    handleError(response);
                    removeIdsFromState([placeholderId]);
                }
            } catch (error) {
                console.error("Error uploading picture:", error);
            }
        }));

        addPictures(placeholders);
    };

    const allIds = state.pictures.flatMap((chunk) => chunk.pictures.map((picture) => picture.id));
    const weekIds = state
        .pictures
        .flatMap((chunk) => chunk.pictures)
        .filter((picture) =>
            moment(picture.uploadedAt).isAfter(moment().subtract(7, "days"))
        ).map((picture) => picture.id);

    const handleDeselectAll = () => {
        updateState(_ => ({
            selected: [],
        }));
    }

    const handleSelectAll = async () => {
        if (state.selected.length !== allIds.length) {
            updateState(_ => ({
                selected: [...allIds],
            }));
        } else {
            updateState(_ => ({
                selected: [],
            }));
        }
    }

    const getPicturesUploadThisWeek = async () => {
        const myId = Storage.getId();
        if (myId === null) return;
        const resp = await UserAPI.getWeeklyUploads(myId)
        if (resp.status === 200) {
            setNumberImported(resp.body);
        } else {
            handleError(resp);
        }
    }

    const removeIdsFromState = (ids: string[]) => {
        updateState(prev => ({
            pictures: prev.pictures.map((chunk) => ({
                ...chunk,
                pictures: chunk.pictures.filter((picture) => !ids.includes(picture.id)),
            })).filter((chunk) => chunk.pictures.length > 0),
            selected: prev.selected.filter((id) => !ids.includes(id)),
        }));
    }

    const handleDelete = async (id: string) => {
        const picture = getPicture(id);
        if (!picture || picture.deleting) return;

        updatePicture(id, { deleting: true });

        const response = await PicturesAPI.deletePicture(id)
        if (response.status >= 200 && response.status < 300) {
            getPicturesUploadThisWeek()
            removeIdsFromState([id]);
        } else {
            handleError(response);
        }
    }

    const handleDeleteSelected = async () => {
        if (state.selected.length > 0) {
            const deleting = [...state.selected]
            updateState(_ => ({
                deleting: true,
            }));
            const datas = {
                bulkIds: deleting,
            }
            const response = await PicturesAPI.deletePictures(datas);
            if (response.status >= 200 && response.status < 300) {
                getPicturesUploadThisWeek()
                removeIdsFromState(deleting);
                updateState(_ => ({
                    deleting: false,
                }));
            } else {
                handleError(response);
                updateState(_ => ({
                    deleting: false,
                }));
            }
        }
    }

    const handleSelect = async (id: string, place?: { id?: string, label?: string }) => {
        if (state.selected.includes(id)) {
            updateState(prev => ({
                selected: prev.selected.filter((selectedId) => selectedId !== id),
            }));
        } else {
            updateState(prev => ({
                selected: [...prev.selected, id],
                defaultData: {
                    place: {
                        id: place?.id || "",
                        label: place?.label || "",
                    }
                }
            }));
        }
    }

    useEffect(() => {
        if (state.selected.length === 0) {
            updateState(_ => ({
                lateralOpen: false,
            }));
        } else {
            updateState(_ => ({
                lateralOpen: true,
            }));
        }
    }, [state.selected]);

    const handleCloseLateral = () => {
        updateState(_ => ({
            lateralOpen: false,
        }));
    }

    useEffect(() => {
        if (acceptedFiles) {
            handleUpload(acceptedFiles);
            if (inputRef.current)
                inputRef.current.value = "";
        }
    }, [acceptedFiles]);

    return (
        <>
            <div className="min-h-screen bg-white relative">
                <Loader loading={state.loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col md:flex-row h-full pb-[340px] md:pb-36 -mt-4">
                    <div className={`md:sticky md:top-16 flex flex-col h-fit md:h-full transition-all ${state.lateralOpen ? " w-full md:w-2/3" : "w-full"}`}>
                        <div className="flex flex-row items-center pr-8 pb-4 justify-between w-full bg-white">
                            <h1 className="mt-8 ml-7 mb-2 text-xl w-1/2 md:w-auto md:text-4xl whitespace-nowrap">{t("myphotos")}</h1>
                            <div className="flex flex-col h-fit w-1/2 text-xs md:text-base md:w-fit pt-6 whitespace-nowrap">
                                <InputFileButton onFilesSelected={handleUpload} buttonText={t("importphotos")} />
                            </div>
                        </div>
                        <div className="mx-4 md:mx-8 px-2 md:px-4 py-2 bg-gray-50 rounded-lg h-full grow mb-16">
                            <div className="flex flex-row flex-wrap md:flex-nowrap justify-between items-center">
                                <div className="flex flex-row w-full md:w-fit h-fit items-center">
                                    <Chip
                                        value={numberImported}
                                        color="orange"
                                        className="mr-2"
                                    />
                                    <Typography color="gray" className="text-xs md:text-base text-gray-800">
                                        {t("thisweekpictures")}
                                    </Typography>
                                </div>
                                <div className="flex flex-row w-fit h-fit items-center">
                                    {state.deleting
                                        ? <Spinner color="orange" />
                                        : (
                                            <Typography className={`${state.selected.length === 0 ? "text-gray-500" : "text-gray-800 hover:text-gray-400 cursor-pointer"} text-xs md:text-base`} onClick={() => handleDeleteSelected()}>
                                                {t("delete_select")}
                                            </Typography>
                                        )
                                    }
                                    <Typography
                                        onClick={() => handleSelectAll()}
                                        className="text-gray-800 ml-4 cursor-pointer hover:text-gray-500 text-xs md:text-base"
                                    >
                                        {t("selectall")}
                                    </Typography>
                                    <Checkbox onClick={() => handleSelectAll()} checked={state.selected.length === allIds.length} color="orange" className={`${state.selected.length === 0 && "bg-white"} hover:text-gray-300`} />
                                </div>
                            </div>
                            <div {...getRootProps({ className: "dropzone" })}>
                                {state.pictures.length > 0 ? state.pictures.map((chunk) => (
                                    <DateChunk
                                        chunk={chunk}
                                        lateralOpen={state.lateralOpen}
                                        onDelete={handleDelete}
                                        onSelected={handleSelect}
                                        selectedIds={state.selected}
                                        key={chunk.date}
                                    />))
                                    : <>
                                        <input {...getInputProps()} />
                                        <p className="w-full border border-dashed text-center text-sm text-gray-700 px-4 py-12 mt-4 rounded">
                                            {t("dragpictures")}
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <SideUpload
                        open={state.lateralOpen}
                        closeDrawer={handleCloseLateral}
                        number={state.selected.length}
                        bulkIds={state.selected}
                        defaultData={state.defaultData}
                        resetBulk={handleDeselectAll}
                        getMyPictures={fetchPictures}
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}



