import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Collapse, Input, Radio, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateAutonomous, updateBranding, updateCommercialUse, updateDigitalModels, updateForSale, updatePrint, updatePrintCount, updateSaleDesk, updateSaleMerch, updateSalePaper } from "../../../services/redux/actions/contractActions";

export default function Restrictions({
    openGeneralites,
    setOpenDescription,
    isInError
}: Readonly<{
    openGeneralites: boolean;
    setOpenDescription: Function;
    isInError: boolean
}>) {
    const { t } = useTranslation();
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();

    const handleCheckCommercialUse = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateCommercialUse(e.target.checked));
    };

    const handleCheckAutonomous = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateAutonomous(e.target.checked));
    };

    const handleCheckBranding = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateBranding(e.target.checked));
    };

    const handleChangeForSale = (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateForSale(value));
    };

    const handleChangeSalePaper = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSalePaper(Number(e.target.value)))
    };

    const handleChangeSaleDesk = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSaleDesk(Number(e.target.value)))
    };

    const handleChangeSaleMerch = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSaleMerch(Number(e.target.value)))
    };

    const handleCheckDigitalModels = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateDigitalModels(e.target.checked));
    }

    const handleCheckPrint = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updatePrint(e.target.checked));
    }

    const handleChangePrintCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updatePrintCount(Number(e.target.value)))
    };

    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openGeneralites)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">4. {t("restricted_usage")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openGeneralites ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openGeneralites} className="flex flex-col gap-4 pt-2 px-4">
                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useEmployeur"
                    value="one"
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("illegal_uses")}</Typography>}
                    checked
                    disabled
                    id="section3"
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 text-gray-500`}>
                    {t("illegal_uses_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] mr-8 sm:mr-0 forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useEmployeur"
                    value="one"
                    onChange={handleCheckCommercialUse}
                    label={<div className="w-full md:w-full break-words ">{t("commercial_uses")}</div>}
                    checked={contract.commercialUse}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.commercialUse ? "" : "text-gray-500"}`}>
                    {t("commercial_uses_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useTraitant"
                    value="allOrga"
                    onChange={handleCheckAutonomous}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("autonomous_file")}</Typography>}
                    checked={contract.autonomous}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.autonomous ? "" : "text-gray-500"}`}>
                    {t("autonomous_file_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useTraitant"
                    value="allOrga"
                    onChange={handleCheckBranding}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("branding")}</Typography>}
                    checked={contract.branding}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.branding ? "" : "text-gray-500"}`}>
                    {t("branding_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useEmployeur"
                    value="one"
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("sensible")}</Typography>}
                    checked
                    disabled
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 text-gray-500`}>
                    {t("sensible_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    id="chooseKindOfResell"
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useEmployeur"
                    value="one"
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("paternity")}</Typography>}
                    checked
                    disabled
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 text-gray-500`}>
                    {t("paternity_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="reventeProduit"
                    value="none"
                    onChange={handleChangeForSale("false")}
                    label={<Typography variant="paragraph" className={`font-semibold text-justify w-full md:w-full break-words ${isInError && "text-red-500"}`}>{t("resell_no")}</Typography>}
                    checked={contract.forSale === "false"}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${!contract.forSale ? "" : "text-gray-500"}`}>
                    {t("resell_no_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="reventeProduit"
                    value="none"
                    onChange={handleChangeForSale("true")}
                    label={<Typography variant="paragraph" className={`font-semibold text-justify w-full md:w-full break-words ${isInError && "text-red-500"}`}>{t("resell_limited")}</Typography>}
                    checked={contract.forSale === "true"}
                />
                <Typography
                    variant="small"
                    className={`flex flex-col md:flex-row items-center gap-2 w-full text-justify -mt-4 px-4 ${
                        contract.forSale === "true" ? "" : "text-gray-500"
                    } my-0.5`}
                >
                    {t("resell_jusqua")}
                    <div className="flex flex-row items-center gap-2 bg-white">
                        <Input
                            type="number"
                            color="orange"
                            label={t("nombre_exemplaire")}
                            onChange={handleChangeSalePaper}
                            value={contract.salePaper}
                            disabled={contract.forSale === "false"}
                            min={0}
                        />
                    </div>
                    {t("resell_limited_p1_end")}
                </Typography>
                <Typography
                    variant="small"
                    className={`flex flex-col md:flex-row items-center gap-2 w-full text-justify -mt-4 px-4 ${
                        contract.forSale === "true" ? "" : "text-gray-500"
                    } my-0.5`}
                >
                    {t("resell_jusqua")}
                    <div className="flex flex-row items-center gap-2 bg-white">
                        <Input
                            type="number"
                            color="orange"
                            label={t("nombre_exemplaire")}
                            onChange={handleChangeSaleDesk}
                            value={contract.saleDesk}
                            disabled={contract.forSale === "false"}
                            min={0}
                        />
                    </div>
                    {t("resell_limited_p2_end")}
                </Typography>
                <Typography
                    variant="small"
                    className={`flex flex-col md:flex-row items-center gap-2 w-full text-justify -mt-4 px-4 ${
                        contract.forSale === "true" ? "" : "text-gray-500"
                    } my-0.5`}
                >
                    {t("resell_jusqua")}
                    <div className="flex flex-row items-center gap-2">
                        <Input
                            type="number"
                            color="orange"
                            label={t("nombre_exemplaire")}
                            onChange={handleChangeSaleMerch}
                            value={contract.saleMerch}
                            disabled={contract.forSale === "false"}
                            className="bg-white"
                            min={0}
                        />
                    </div>
                    {t("resell_limited_p3_end")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useTraitant"
                    value="allOrga"
                    onChange={handleCheckDigitalModels}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("digital_models_no")}</Typography>}
                    checked={contract.digitalModels}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.digitalModels ? "" : "text-gray-500"}`}>
                    {t("digital_models_no_d")}
                </Typography>

                <hr className="w-3/4 mx-auto border-gray-400 mt-4" />

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useTraitant"
                    value="allOrga"
                    onChange={handleCheckPrint}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("print_limited")}</Typography>}
                    checked={contract.print}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.print ? "" : "text-gray-500"} flex flex-col md:flex-row flex-wrap items-center gap-2`}>
                    <p className="w-fit">{t("print_limited_d_1")}</p>
                    <div className="w-fit">
                        <Input
                            type="number"
                            color="orange"
                            label={t("nombre_exemplaire")}
                            onChange={handleChangePrintCount}
                            value={contract.printCount}
                            disabled={!contract.print}
                            min={0}
                            className="w-fit bg-white"
                        />
                    </div>
                    <p className="w-fit">{t("print_limited_d_2")}</p>
                    <p className="w-fit">{t("print_limited_d_3")}</p>
                </Typography>
            </Collapse>
        </div>
    );
}
