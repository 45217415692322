import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { Storage } from "../../../../services/storage";
import SharingBox from "../../../../components/Box/SharingBox";
import { toast } from "react-toastify";
import FavoriteAPI from "../../../../services/API/Clients/FavoriteAPI";
import CardUserWithData from "../../../../components/Box/CardUserWithData";
import { useSelector } from "react-redux";
import { OpenGalleryReport } from "../../../../components/Modals/ModalGallerieReport";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import { handleError } from "../../../../services/Errors/handleErrors";
import moment from "moment";
import { useDetectInvisibleOnScroll } from "../../../../services/hooks/useDetectiveOnScroll";

const token = Storage.getId();
const myId = Storage.getId();

export default function GalleryHeader({
    searchBar,
    picturesLength,
    data,
    setOpenModal,
    fetchData,
}: Readonly<{ searchBar: boolean; picturesLength: number; data: GalleryInterface; setOpenModal: any; fetchData: any }>) {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);
    const [hidden, setHidden] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const [isInvisible, ref] = useDetectInvisibleOnScroll();

    const handleChangeFav = async () => {
        if (general && general.me && general.me.portfolio && general.me.portfolio.id) {
            const datas = {
                picture: `/api/public/pictures/${data.id}`,
                owner: `/api/users/${general.me.portfolio.id}`,
            };
            if (!data.isFavorited) {
                const response = await FavoriteAPI.favGallerie(datas);
                if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
                else handleError(response);
            } else {
                const response = await FavoriteAPI.unFavGallerie(datas);
                if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
                else handleError(response);
            }
            fetchData();
        }
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    return (
        <> 
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <PictureDrawerForFilters />
            <div
                className="md:hidden relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${(data?.banner?.publicPath) ?? DefaultBannerEzoom})`,
                }}
            ></div>

           {window.innerWidth < 768 && (
                <motion.div
                    variants={{
                        visible: { top: "54px", paddingTop: "28px", paddingBottom: "20px" },
                        hidden: { top: "0px", paddingTop: "4px", paddingBottom: "10px" },
                        barsInactive: { top: "54px", paddingTop: "22px", paddingBottom: "18px" },
                        barsActive: { top: "102px", paddingTop: "10px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="md:hidden sticky md:static h-16 md:h-0 z-30 px-1 md:pb-0 md:overflow-hidden flex flex-row justify-between items-center bg-white"
                >
                    <div className={`flex flex-row justify-start gap-4 w-full overflow-hidden relative`}>
                        <div className={`${isInvisible ? "opacity-100" : "opacity-0 hidden"} transition-all pt-3 pl-2 w-fit`}>
                            <Typography className="flex md:hidden justify-center text-4xl font-bold text-center font-sans w-fit" style={{ fontFamily: "Great Vibes" }}>
                                {data.title}
                            </Typography>
                        </div>
                        <div className={`${isInvisible ? "opacity-0 hidden" : "opacity-100"} transition-all w-fit`}>
                            <CardUserWithData data={data.portfolio} isVerified={data.owner.verified} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-end gap-2 w-fit pr-2">
                        {myId && myId === data.owner.id && (
                            <a href={`/gallery/${data.id}/edit`}>
                                <IconButton 
                                    size="sm"
                                    color="white" 
                                    variant="outlined"
                                    className="md:hidden items-center border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                >
                                    <FontAwesomeIcon icon="pen" />
                                </IconButton>
                            </a>
                        )}
                        {myId && myId === data.owner.id && (
                            <IconButton 
                                color="white" 
                                size="sm"
                                variant="outlined"
                                className="md:hidden border-indian-500 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-indian-500"
                                onClick={() => setOpenModal(true)}
                            >
                                <FontAwesomeIcon icon="trash" />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => !Storage.getToken() ? setOpenInvitation(true) :  handleChangeFav()}
                            color={data.isFavorited ? "red" : "white"}
                            size="sm"
                            className={`ring-1 ${
                                data.isFavorited ? "ring-red-500/50 rounded-full hover:bg-red-400" : "ring-gray-400/50 rounded-full hover:bg-gray-50"
                            } transition-all`}
                        >
                            <FontAwesomeIcon icon="heart" />
                        </IconButton>
                        {data.public &&
                            <SharingBox />
                        }
                        {myId && myId !== data.owner.id && (
                            <Popover placement="bottom-end">
                                <PopoverHandler>
                                    <IconButton size="sm" color="white" className="ring-1 ring-gray-400/50 transition-all duration-400 rounded-full hover:bg-gray-50">
                                        <FontAwesomeIcon icon="ellipsis-vertical" />
                                    </IconButton>
                                </PopoverHandler>
                                <PopoverContent className="p-0">
                                    <button
                                        className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(true) :  data?.id && OpenGalleryReport(data.id, t)}
                                    >
                                        {t("report")}
                                    </button>
                                    {token && token === data.owner.id && (
                                        <a href={`/gallery/${data.id}/edit`}>
                                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">{t("edit")}</div>
                                        </a>
                                    )}
                                </PopoverContent>
                            </Popover>
                        )}
                    </div>
                </motion.div>
           )}
            <div className="flex md:hidden flex-col items-center justify-center mt-4 px-4 gap-1">
                <Typography ref={ref} className="flex md:hidden justify-center text-4xl font-bold text-center font-sans" style={{ fontFamily: "Great Vibes" }}>
                    {data.title}
                </Typography>
                {(data.date || data?.place?.label) && (
                    <div className="flex gap-2 items-center justify-center text-center">
                        <span>{data.date && moment(data.date).format("DD.MM.YYYY")}</span>
                        <span>{data?.place?.label}</span>
                    </div>
                )}
                {(data.private || !data.published) && (
                    <div className="flex flex-col gap-2 justify-center items-center">
                        {data.private && (
                            <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                {t("privateGallery")}
                            </div>
                        )}
                        {!data.published && (
                            <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                {t("unpublishedGallery")}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="flex md:hidden flex-col md:flex-row justify-between px-4 mt-4 max-w-7xl mx-auto w-full">
                <div className="flex flex-row flex-wrap justify-center gap-4 px-4 mx-auto w-full md:w-1/3">
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        size="sm"
                        variant="ghost"
                        value={`${picturesLength}  ${t("photos")}`}
                        icon={<FontAwesomeIcon icon="images" className="h-3 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        size="sm"
                        value={`${data.viewCount} ${t("views")}`}
                        icon={<FontAwesomeIcon icon="eye" className="h-3 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        size="sm"
                        value={`${data.favoriteCount} ${t("favs")}`}
                        icon={<FontAwesomeIcon icon="heart" className="h-3 mt-0.5" />}
                    />
                </div>
                <div className="flex flex-col justify-center md:justify-end md:items-end  mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3 text-gray-500 text-right gap-1">
                    
                </div>
            </div>

            
           {window.innerWidth < 768 && (
                <motion.div
                    variants={{
                        visible: { top: "118px", paddingTop: "0px" },
                        hidden: { top: "56px", paddingTop: "4px" },
                        barsInactive: { top: "118px", paddingTop: "0px" },
                        barsActive: { top: "165px", paddingTop: "0px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="flex md:hidden justify-end gap-2 pr-2 mt-4 md:-mb-4 sticky top-0 md:pt-0 pb-1 md:pb-0 bg-white z-30 border-b border-gray-200"
                >
                    <MobileFilterButton />
                </motion.div>
            )}
        </>
    );
}
