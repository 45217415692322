import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import Photographer from "../../Box/Photograph/Photographer";
import { PortfolioSmallType } from "../../Interfaces/PortfolioType";
import { FilterProvider } from "../provider/FilterProvider"; // Use FilterProvider here
import DrawerForFilter from "../mobile/DrawerForFilter";
import {
  CompatibleFilterList,
  FilterList,
  FilterRenderConfiguration,
  FilterSchema,
  FilterType,
  PaginatedResponse,
  SearchInterface,
} from "../types/global";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { handleError } from "../../../services/Errors/handleErrors";
import { FilterConfiguration } from "../filters/FilterConfiguration";

interface EnumValue {
  enumValue: string;
  frLabel: string;
  enLabel: string;
}

interface PortfolioCompatibleFilters extends CompatibleFilterList {
  service: EnumValue[];
  region: {
    code: string;
  }[];
}

interface PortfolioFilterList extends FilterList {
  query?: string;
  service?: string;
  country?: string;
  region?: string;
  photoStyle?: string;
  favorites?: boolean;
  plan?: string;
  hasServices?: boolean;
}

const PortfolioFilterSchema: FilterSchema<PortfolioFilterList> = {
  query: "string",
  service: "string",
  country: "string",
  region: "string",
  photoStyle: "string",
  favorites: "boolean",
  plan: "string",
  hasServices: "boolean",
};

const PortfolioFiltersRender: FilterRenderConfiguration<PortfolioCompatibleFilters> = {
  service: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
  region: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: { code: string }) => o.code,
    getValue: (o: { code: string }) => o.code,
  },
};

const fetchPortfolios = async (search: SearchInterface<PortfolioFilterList>) => {
  const response = await PortfolioAPI.searchPortfoliosPagination(
    search.filters.getApiQueryParams(),
    search.page
  );
  if (response.status === 200) {
    return response.body as PaginatedResponse<PortfolioSmallType>;
  } else {
    handleError(response);
  }
};

const fetchCompatibleFilters = async (filters: FilterConfiguration<PortfolioFilterList>) => {
  const response = await PortfolioAPI.compatibleFilters(filters.getApiQueryParams());
  if (response.status === 200) {
    return response.body as PortfolioCompatibleFilters;
  } else {
    handleError(response);
  }
};

interface PortfolioResultProps {
  filters?: boolean;
  hideFilters?: string[];
  overrides?: PortfolioFilterList;
  children?: React.ReactNode; // Accept children to be rendered within the FilterProvider
  defaultSort?: string;
  reloadDate?: Date;
}

const PortfolioNoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
      <FontAwesomeIcon icon="image" className="mt-12 text-4xl text-gray-500/50 mb-4" />
      <Typography className="text-base text-gray-700 mb-12">{t("noPhotographers")}</Typography>
    </div>
  );
};

export const PortfolioResult = (props: PortfolioResultProps) => {
  return (
    <FilterProvider
      filtersSchema={PortfolioFilterSchema}
      filters={props.filters ?? true}
      hideFilters={props.hideFilters ?? []}
      filtersRender={PortfolioFiltersRender}
      fetchResults={fetchPortfolios}
      fetchFilters={fetchCompatibleFilters}
      fetchGalleries={undefined} // Not required for portfolios
      filterOverrides={props.overrides}
      NoResults={<PortfolioNoResults />}
      sortOptions={[
        { key: "followed", value: "sort.follow" },
        { key: "note", value: "sort.note" },
        { key: "nearest", value: "sort.nearest" },
        { key: "cheapest", value: "sort.cheapest" },
        { key: "name", value: "sort.name" },
      ]}
      renderEntity={(item, index, context, loadMore) => (
        <Photographer key={item.id} data={item} />
      )}
      defaultSort={props.defaultSort}
      reloadDate={props.reloadDate}
    >
      {/* Render any children passed to PortfolioResult */}
      {props.children}
    </FilterProvider>
  );
};

// For the drawer filters:
export const PortfolioDrawerForFilters = () => (
  <DrawerForFilter<PortfolioFilterList, PortfolioCompatibleFilters>
    renderConfiguration={PortfolioFiltersRender}
  />
);
