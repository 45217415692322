import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function TableResume({ currentUrl, monthly, codePromoIsValid, discount }: { currentUrl: string, monthly: boolean, codePromoIsValid: string, discount: number }) {
    const { t } = useTranslation();

    const calculPrice = () => {
        if (currentUrl === "pro") {
            if (codePromoIsValid === "valid" && discount > 0) {
                if (monthly) {
                    return (22 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (22 * (1 - (discount/100))).toString()
                } else {
                    return (198 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (198 * (1 - (discount/100))).toString()
                }
            } else
                return monthly ? t("chfSpace") + "22.-" : t("chfSpace") + "198.-";
        } else {
            if (codePromoIsValid === "valid" && discount > 0) {
                if (monthly) {
                    return (8 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (8 * (1 - (discount/100))).toString()
                } else {
                    return (72 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (72 * (1 - (discount/100))).toString()
                }
            } else
            return monthly ? t("chfSpace") + "8.-" : t("chfSpace") + "72.-";
        }
    }

    return (
        <table className="w-full table-auto text-left overflow-x-auto">
            <tr>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-black"
                    >
                        {t("licence")}
                    </Typography>
                </th>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-black"
                    >
                        {t("periodicity")}
                    </Typography>
                </th>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-center text-black"
                    >
                        {monthly ? t("pricettcMonth") : t("pricettcYear")}
                    </Typography>
                </th>
            </tr>
            <tr>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {currentUrl === "pro" ? t("proSubscription") : t("premiumSubscription")}
                    </Typography>
                </td>
                <td className="p-4">
                    
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {t(monthly ? "monthly" : "yearly")}
                    </Typography>
                </td>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-center"
                    >
                        {calculPrice()}
                    </Typography>
                </td>
            </tr>
        </table>
    )
}