import { useRef, useState, useEffect } from 'react';

interface ObserverSettings {
    rootMargin?: string;
    threshold?: number;
}

export const useDetectInvisibleOnScroll = (
    observerSettings: ObserverSettings = { rootMargin: '0px', threshold: 0 }
): [boolean, React.RefObject<HTMLElement>] => {
    const [isInvisible, setIsInvisible] = useState(false);
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const handleIntersect = ([entry]: IntersectionObserverEntry[]) => {
            setIsInvisible(!entry.isIntersecting);
        };

        const observer = new IntersectionObserver(handleIntersect, observerSettings);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [observerSettings]);

    return [isInvisible, ref];
};
