import { NavigateFunction } from "react-router-dom";
import { FilterList, FilterSchema } from "../types/global";
import { FilterStoreInterface } from "../../../services/redux/reducers/filtersReducer";
import { Dispatch } from "@reduxjs/toolkit";

export class FilterConfiguration<FiltersType extends FilterList> {

    public initStore(schema: FilterSchema<FiltersType>) {
        const url = window.location.search;
        const urlParams = new URLSearchParams(url);
        const filters = {} as FiltersType;

        for (const key in schema) {
            if (schema[key] === 'boolean') {
                (filters[key as keyof FiltersType] as boolean | undefined) = urlParams.get(key) === 'true' || undefined;
            } else {
                (filters[key as keyof FiltersType] as string | undefined) = urlParams.get(key) ?? undefined;
            }
        };

        this.dispatch({ type: "INIT_FILTERS", payload: { filterSchema: schema, current: filters, defaultSort: this.defaultSort } });
    }

    constructor(
        private navigate: NavigateFunction,
        private filterStore: FilterStoreInterface,
        private dispatch: Dispatch,
        private override?: FiltersType,
        private defaultSort?: string
    ) { }

    private buildFrontendQueryParams() {
        const filters = this.getAdditionalFilters();
        let queryParams = [];

        const url = window.location.search;
        const urlParams = new URLSearchParams(url);

        const schema = this.filterStore.filterSchema;

        for (const key in filters) {
            if (filters[key as keyof FiltersType] !== undefined) {
                queryParams.push(`${key}=${filters[key as keyof FiltersType]}`);
            }
        }

        urlParams.forEach((value, key) => {
            if (schema[key] === undefined) {
                queryParams.push(`${key}=${value}`);
            }
        });

        return queryParams.join('&');
    }

    public getApiQueryParams() {
        const queryParams = [];
        const filters = this.getAllFilters();
        for (const key in filters) {
            if (key === "galleries")
                continue;
            if (filters[key as keyof FiltersType] !== undefined)
                queryParams.push(`${key}=${filters[key as keyof FiltersType]}`);
        }
        if (this.filterStore.sortBy)
            queryParams.push(`order=${this.filterStore.sortBy}`);
        return queryParams.join('&');
    }

    public getAllFilters(): FiltersType {
        return { ...this.filterStore.currentFilters, ...(this.override ?? {}) } as FiltersType;
    }

    public getAdditionalFilters(): FiltersType {
        return (this.filterStore.currentFilters ?? {}) as FiltersType;
    }

    public hasEnabledFilters(except: string[] = []): boolean {
        const filters = this.getAdditionalFilters();
        for (const key in filters) {
            if (filters[key as keyof FiltersType] !== undefined && !except.includes(key)) {
                return true;
            }
        }
        return false;
    }

    public updateUrl() {
        this.navigate(`?${this.buildFrontendQueryParams()}`, { replace: true });
    }

    public addFilter(filter: keyof FiltersType, value: string | boolean) {
        this.dispatch({ type: "ADD_FILTER", payload: { filter, value } });
    }

    public sortBy(sortBy: string | undefined) {
        if (sortBy === "default")
            return this.dispatch({ type: "SORT_BY", payload: { sortBy: undefined } });
        this.dispatch({ type: "SORT_BY", payload: { sortBy } });
    }

    public getSortBy() {
        return this.filterStore.sortBy;
    }

    public removeFilter(filter: keyof FiltersType) {
        this.dispatch({ type: "REMOVE_FILTER", payload: { filter } });
    }

    public clearFilters() {
        this.dispatch({ type: "CLEAR_FILTERS" });
    }
}