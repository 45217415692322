import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Tooltip, Typography } from "@material-tailwind/react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import SharingBox from "../../../../components/Box/SharingBox";
import { NavigationCustomStyles } from "../../../../components/CustomStyles/NavigationCustomStyles";
import { OpenReportPortfolio } from "../../../../components/Modals/ModalPortfolioReport";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { updatePortfolio, updateRepartition, updateSubscribed } from "../../../../services/redux/actions/formAction";
import { formatLongNumber } from "../../../../services/redux/actions/formatNumber";
import { RootState } from "../../../../services/redux/store";
import { Storage } from "../../../../services/storage";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import UserAPI from "../../../../services/API/Clients/UserAPI";

export default function HeaderMobile({
    searchBar,
    actualUrl,
    isPersonnal,
    handleLinkClick,
}: Readonly<{ searchBar: boolean; actualUrl: string; isPersonnal: boolean; handleLinkClick: (url: string) => void }>) {
    const [hidden, setHidden] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const [firstTime, setFirstTime] = React.useState<string[]>([]);
    const general = useSelector((state: RootState) => state.general);
    const portfolio = useSelector((state: RootState) => state.form);
    const myId = Storage.getId();
    const dispatch = useDispatch();

    const chooseUrl = () => {
        if (isPersonnal) {
            switch (window.location.pathname.split("/")[2]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[3]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        } else {
            switch (window.location.pathname.split("/")[3]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[4]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        }
    };

    const featurePages = portfolio?.features?.pages;

    const MenuList = Object.entries(featurePages ?? {})
        .filter(([key, value]) => value) // Filtrer uniquement les clés avec des valeurs true
        .map(([key, _]) => {
            let item = {};

            if (key === "photos") {
                item = {
                    value: key,
                    url: isPersonnal ? `/${portfolio.path}/about` : `/portfolio/${id}/${key}`,
                    label: `${t("photos")} (${formatLongNumber(portfolio.pictureCount ?? 0)})`,
                };
            } else if (key === "services") {
                item = {
                    value: "performances",
                    url: isPersonnal ? `/${portfolio.path}/performances` : `/portfolio/${id}/performances`,
                    label: `${t("performances")} (${formatLongNumber(portfolio?.services.pagination.totalItems ?? 0)})`,
                };
            } else if (key === "testimonials") {
                item = {
                    value: "opinion",
                    url: isPersonnal ? `/${portfolio.path}/opinion` : `/portfolio/${id}/opinion`,
                    label: `${t("opinion")} (${portfolio.opinions.data.length ?? 0})`,
                };
            } else {
                item = {
                    value: key,
                    url: isPersonnal ? `/${portfolio.path}/${key}` : `/portfolio/${id}/${key}`,
                    label: t(key),
                };
            }

            return item;
        });

    const followPortfolio = async () => {
        if (myId) {
            const datas = {
                owner: `/api/users/${myId}`,
                portfolio: `/api/public/portfolios/${id}`,
            };
            if (portfolio.isSubscribed) {
                const responseUnfollow = await PortfolioAPI.unfollow(datas);
                if (responseUnfollow.status === 200 || responseUnfollow.status === 201 || responseUnfollow.status === 204) {
                    toast.success(t("portfolio_unfollow_success"));
                    dispatch(updateSubscribed(false));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseUnfollow);
                }
            } else {
                const responseFollow = await PortfolioAPI.follow(datas);
                if (responseFollow.status === 200 || responseFollow.status === 201) {
                    toast.success(t("portfolio_follow_success"));
                    dispatch(updateSubscribed(true));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseFollow);
                }
            }
        }
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await UserAPI.me()
            if (response.status === 200 || response.status === 201) {
                setFirstTime(response.body.closedDialogs)
            }
        }
        fetchData()
    }, [])

    const handleDisabled = async () => {
        if (!general?.me?.id) return;
        const dataListDisables = {
            "dialogToClose": "portfolio_welcome"
        }
        const response = await UserAPI.setDialogs(general?.me?.id, dataListDisables);
        if (response.status === 200 || response.status === 201) {
            setFirstTime(response.body.closedDialogs)
            navigate("/portfolio/edit/photos")
        } else {
            handleError(response);
        }
    }

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div className={`${!firstTime.includes("portfolio_welcome") ? "flex md:hidden" : "hidden" } z-40 fixed top-0 left-0 bg-black/50 w-screen h-screen`}>
            </div>
            <PictureDrawerForFilters />
            <div
                className="flex md:hidden relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (portfolio.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${portfolio.banner ?? DefaultBannerEzoom})`,
                }}
            ></div>

            {/* <div className="md:hidden sticky md:static h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex flex-row justify-between items-end"> */}
            
           {window.innerWidth < 768 && (
                <motion.div
                    variants={{
                        visible: { top: "54px", paddingTop: "28px" },
                        hidden: { top: "0px", paddingTop: "0px" },
                        barsInactive: { top: "54px", paddingTop: "22px" },
                        barsActive: { top: "102px", paddingTop: "50px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="md:hidden sticky h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex flex-row justify-between items-end"
                >
                    <div className="relative h-12 pt-1">
                        <div className="flex flex-row gap-4 items-center mt-1">
                            <div className="w-12 h-12 bg-white rounded-full">
                                <img
                                    alt="vector"
                                    className="w-full h-full object-cover object-center rounded-full border-4 border-gray-50"
                                    src={portfolio.loading ? DefaultPictureEzoom : portfolio.picture ?? DefaultPictureEzoom}
                                />
                            </div>
                            <div className="flex flex-col justify-center">
                                <Typography className="text-xl font-bold flex items-center gap-4">
                                    {portfolio.name}
                                    {portfolio.owner.verified && (<FontAwesomeIcon icon="check-circle" className="w-3 h-3 text-gray-500" />)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="flex relative flex-row justify-end gap-2 px-2">
                        {!portfolio.loading && myId && myId === portfolio.owner.id && (
                            <Tooltip
                                className="md:hidden bg-white p-0"
                                placement="bottom-end"
                                content={(
                                    <div className="bg-orange-50/20 text-sm text-black px-4 py-3 font-sans rounded-lg">
                                        <div className="absolute -top-2 right-16 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-[#fbf2e1]"></div>
                                        <p className="flex flex-col">
                                            <span dangerouslySetInnerHTML={{ __html: t("datainfoPortfolio") }} />
                                            <button
                                                className="ml-auto border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 mt-2 rounded-full"
                                                style={{
                                                    transition: "all .25s ease-in-out",
                                                }}
                                                onClick={() => handleDisabled()}
                                            >
                                                <span className="flex items-center justify-between"><FontAwesomeIcon icon="check-circle" className="mr-1" /> {t("understand")}</span>
                                            </button>
                                        </p>
                                    </div>
                                )}
                                open={!firstTime.includes("portfolio_welcome")}
                            >
                                <a href={chooseUrl()}>
                                    <IconButton 
                                        size="sm" 
                                        color="white"   
                                        variant="outlined"
                                        className=" border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                    >
                                        <FontAwesomeIcon icon="pen" />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        )}
                        {myId !== portfolio.owner.id && (
                            <IconButton
                                size="sm"
                                color="white"
                                variant="outlined"
                                className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : followPortfolio()}
                            >
                                {portfolio.isSubscribed ? <FontAwesomeIcon icon="user-minus" /> : <FontAwesomeIcon icon="user-plus" />}
                            </IconButton>
                        )}
                        {portfolio.public && (
                            <SharingBox />
                        )}
                        {myId !== portfolio.owner.id && (
                            <Popover placement="bottom-end">
                                <PopoverHandler>
                                    <IconButton
                                        size="sm"
                                        color="white"
                                        variant="outlined"
                                        className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                    >
                                        <FontAwesomeIcon icon="ellipsis-vertical" />
                                    </IconButton>
                                </PopoverHandler>
                                <PopoverContent className="p-0">
                                    <button
                                        className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : portfolio?.id && OpenReportPortfolio(portfolio.id, t)}
                                    >
                                        {t("report")}
                                    </button>
                                </PopoverContent>
                            </Popover>
                        )}
                    </div>
                </motion.div>
           )}
            {/* </div> */}

            <div className="flex md:hidden flex-row gap-2 mt-4 items-center w-full justify-center flex-wrap">
                {portfolio.plan === "PRO" && (
                    <Chip
                        className="w-fit h-fit bg-black text-white"
                        color="purple"
                        variant="ghost"
                        value={t("pro")}
                        icon={<FontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
                {portfolio.plan === "PREMIUM" && (
                    <Chip
                        className="w-fit h-fit bg-white text-black border border-black"
                        color="orange"
                        variant="ghost"
                        value={t("premium")}
                        icon={<FontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                        size="sm"  
                    />
                )}

                <Chip
                    className="w-fit h-fit"
                    color="purple"
                    variant="ghost"
                    value={`${portfolio.subscriberCount ?? 0} ${t("subs")}`}
                    icon={<FontAwesomeIcon icon="users" className="h-3 ml-0.5" />}
                    size="sm"
                />
                {(portfolio.avgNote !== -1 && portfolio.features.actions.testimonial) && (
                    <Chip
                        className="w-fit h-fit"
                        color="purple"
                        variant="ghost"
                        value={`${portfolio.avgNote === -1 ? t("nonote") : Number(portfolio.avgNote).toFixed(2)}`}
                        icon={<FontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
            </div>

            {window.innerWidth < 768 && (
                <motion.div
                    variants={{
                        visible: { top: "110px", paddingTop: "0px" },
                        hidden: { top: "56px", paddingTop: "0px" },
                        barsInactive: { top: "110px", paddingTop: "0px" },
                        barsActive: { top: "158px", paddingTop: "0px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="flex md:hidden border-b border-gray-200 w-full mx-auto bg-white mt-4 sticky z-30"
                >
                    <div className={`${actualUrl === "photos" ? "w-1/2" : "w-full"} px-2 pb-2`}>
                        <Select
                            placeholder="Menu"
                            name="Menu"
                            className="w-full relative top-0 bg-white text-xs"
                            options={MenuList}
                            styles={NavigationCustomStyles}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            value={MenuList.find((obj: any) => obj.value === actualUrl)}
                            onChange={(e: any) => e !== null && handleLinkClick(e.url)}
                            isSearchable={false}
                        />
                    </div>
                    <div className={`${actualUrl === "photos" ? "flex" : "hidden"} w-1/2 px-2  gap-2 justify-end`}>
                        <MobileFilterButton />
                    </div>
                </motion.div>
            )}
        </>
    );
}
