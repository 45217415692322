import { Input } from "@material-tailwind/react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export default function InputPhone({ handleChangePhoneNumber, value, alreadyVerified = false } : Readonly<{ handleChangePhoneNumber: Function, value: string, alreadyVerified?: boolean }>) {
    return (
        <>
            {alreadyVerified ? (
                <Input
                    type={"text"}
                    color="orange"
                    className="bg-white"
                    value={value}
                    containerProps={{ className: "min-w-[72px]" }}
                    disabled={true}
                />
            ) : (
                <IntlTelInput
                    inputClassName="z-50 peer w-full max-w-full h-full bg-white text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 focus:py-2 rounded-[7px] border-blue-gray-200 focus:border-deep-purple-500"
                    defaultCountry="ch"
                    onPhoneNumberChange={(status, value, countryData, number, id) => handleChangePhoneNumber(number)}
                    // placeholder={value !== "" ? value : "078 123 45 67"}
                    value={value}
                />
            )}
        </>
    )
}