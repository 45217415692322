import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import OrderAPI from "../../services/API/Clients/OrderAPI";
import SubscriptionAPI from "../../services/API/Clients/SubscriptionAPI";

import { useDispatch } from "react-redux";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import UserAPI from "../../services/API/Clients/UserAPI";
import { updateMe } from "../../services/redux/actions/generalActions";

import Footer from "../../components/Footer/Footer";
import { PictureType } from "../../components/Interfaces/PictureType";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import { Storage } from "../../services/storage";

import isSuccessPicture from "../../assets/images/elements/thanks.png";
import GradientButton from "../../components/Form/Button/GradientButton";

export default function EndTransaction() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;
    const { arg } = useParams();
    const [searchBar, setSearchBar] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);
    const [isASubscription, setIsASubscription] = useState(false);

    const fetchData = async () => {
        const response = await PicturesAPI.backgroundPicture();
        if (response.status === 200) {
            setBackgroundPicture(response.body.picture);
        }
    }

    const fetchDatas = async () => {
        if (!arg) return;
        if (loading) return;
        setLoading(true);
        let success = false;
        let subscriptionDetected = false;

        const splitiginTransaction = window.location.href.split("=");
        const response = await SubscriptionAPI.subscribeTransactionAssert(splitiginTransaction[1], {});
        
        if (response.status === 200 || response.status === 201) {
            const respon = await UserAPI.me();
            if (respon.status === 200 || respon.status === 201) {
                dispatch(updateMe(respon.body));
                setIsASubscription(true);
                subscriptionDetected = true;
                success = true;
            }
        } else {
            const responseBis = await OrderAPI.orderTransactioAssert(splitiginTransaction[1], {});
            if (responseBis.status === 200 || responseBis.status === 201) {
                const respon = await UserAPI.me();
                if (respon.status === 200 || respon.status === 201) {
                    dispatch(updateMe(respon.body));
                    setIsASubscription(false);
                    success = true;
                }
            }
        }

        setIsSuccess(success);
        setLoading(false);

        if (success) {
            toast.success(t("payementSucess"));
            Storage.clearCart();
        } else {
            toast.error(t("payementFailed"));
        }
    }

    useEffect(() => {
        fetchData();
        fetchDatas();
    }, []);

    return (
        <div className="min-h-full">
            <Loader loading={loading} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div
                className="hidden md:block h-full min-h-screen"
                style={{
                    backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <div className="relative min-h-screen flex justify-start items-center">
                <div className="relative w-screen min-h-screen md:min-h-fit mt-0 mb-0 md:h-fit md:w-2/3 xl:w-1/3 bg-white rounded-lg md:mt-0 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <FontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    {loading
                        ? <Spinner color="orange" />
                        : (
                            <>
                                {isSuccess && (
                                    <img src={isSuccessPicture} alt="isSuccessPicture" className="h-44 my-6 mx-auto" />
                                )}
                                <h1 className="text-3xl mb-2 text-center">{isSuccess ? t("payementSucess") : t("payementFailed")}</h1>
                                <p className="text-center text-gray-500 text-sm gap-2 mb-4 mt-2" dangerouslySetInnerHTML={{ __html: isSuccess ? isASubscription ? t("couldClosePageSubscription") : t("couldClosePage") : isASubscription ? t("couldClosePageFailedSubscription") : t("couldClosePageFailed") }}></p>
                                <Link to="/" className=" mx-auto">
                                    <GradientButton
                                        text={t("backToHome")}
                                    />
                                </Link>
                            </>
                        )
                    }
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
