import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, IconButton, Tooltip, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HistoryInterface } from "../../../components/Interfaces/HistoryInterface";
import PaginationCallNextOne from "../../../components/Tables/PaginationCallNextOne";
import OrderAPI from "../../../services/API/Clients/OrderAPI";
import { handleError } from "../../../services/Errors/handleErrors";

function chooseIcon(icon?: string | null) {
    if (!icon || icon === undefined) return "x";
    if (icon === "PAID") return "check";
    return icon === "PENDING" ? "clock" : "x";
}

export default function History({
    history,
    callNextPicture,
}: Readonly<{
    history: HistoryInterface;
    callNextPicture: (arg?: number) => void;
}>) {
    const { t } = useTranslation();
    const [expandedRows, setExpandedRows] = useState<boolean[]>([]);

    const openThisOne = (index: number) => {
        const oldRows = expandedRows;
        const isRowExpanded = oldRows[index];
        const changeMyRow = !isRowExpanded;
        oldRows[index] = changeMyRow;
        setExpandedRows([...oldRows]);
    };

    const getQuittances = async (id: string) => {
        const response = await OrderAPI.quittanceHistory(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            window.open(url);
        } else {
            handleError(response);
        }
    };

    const getContratLicence = async (id: string, picture: string) => {
        const response = await OrderAPI.contratBuySell(id, picture);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            window.open(url);
        } else {
            handleError(response);
        }
    };

    return (
        <>
            {history.data.length > 0 ? (
                <>
                    <div className="w-full overflow-x-auto px-4">
                        <table className="w-full min-w-max table-auto text-left mt-4">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("date")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("time")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("profit")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("sale_ref")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("purchaser")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("invoice")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("payement")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {history.data.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <tr key={item.id} className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"}`}>
                                            <td className="p-4">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {moment(item.createdAt).format("DD.MM.YYYY")}
                                                </Typography>
                                            </td>
                                            <td className="p-4">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {moment(item.createdAt).format("HH:ss")}
                                                </Typography>
                                            </td>
                                            <td className="p-4">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {t("chf")} {item.netWorth}.-
                                                </Typography>
                                            </td>
                                            <td className="p-4">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {item.saleId}
                                                </Typography>
                                            </td>
                                            <td className="p-4">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {item?.buyerActive 
                                                        ? <Link to={`/${item?.buyer?.publicLink}/photos`}>{item?.buyer?.name}</Link> 
                                                        : item?.buyer?.name
                                                    }
                                                </Typography>
                                            </td>
                                            <td className="p-4 text-center">
                                                <IconButton
                                                    onClick={() => getQuittances(item.id)}
                                                    variant="outlined"
                                                    color="orange"
                                                    className="rounded-full hover:text-gray-400 text-orange-400 hover:border-gray-400 transition-all"
                                                >
                                                    <FontAwesomeIcon icon="file-pdf" />
                                                </IconButton>
                                            </td>
                                            <td className="p-4 text-center">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    <Tooltip
                                                        content={`${
                                                            item.withdrawDate === "PAID"
                                                                ? t("saleValidated", { date: moment(item.createdAt).format("DD.MM.YYYY") })
                                                                : item.withdrawDate === "PENDING"
                                                                ? t("salePending", { date: moment(item.createdAt).format("DD.MM.YYYY") })
                                                                : t("saleRefused")
                                                        }`}
                                                        className={`${
                                                            item.withdrawDate === "PAID"
                                                                ? "bg-green-700"
                                                                : item.withdrawDate === "PENDING"
                                                                ? "bg-orange-500"
                                                                : "bg-red-700"
                                                        } max-w-[275px]`}
                                                        placement="left"
                                                        
                                                    >
                                                        <IconButton
                                                            color={`${item.withdrawDate === "PAID" ? "green" : item.withdrawDate === "PENDING" ? "orange" : "red"}`}
                                                        >
                                                            <FontAwesomeIcon icon={chooseIcon(item.withdrawDate)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </td>
                                            <td className="p-4 text-center">
                                                <IconButton
                                                    onClick={() => openThisOne(index)}
                                                    variant="outlined"
                                                    color="orange"
                                                    className="rounded-full hover:text-gray-400 text-orange-400 hover:border-gray-400 transition-all"
                                                >
                                                    <FontAwesomeIcon icon={!expandedRows[index] ? "chevron-down" : "chevron-up"} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                        {expandedRows[index] && (
                                            <tr>
                                                <td colSpan={8}>
                                                    <table className="w-full min-w-max table-auto text-left">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                    {t("image")}
                                                                </Typography>
                                                            </th>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                    {t("product")}
                                                                </Typography>
                                                            </th>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                    {t("ref")}
                                                                </Typography>
                                                            </th>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                    {t("quantity")}
                                                                </Typography>
                                                            </th>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2 rounded-tr">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl text-center">
                                                                    {t("contrat")}
                                                                </Typography>
                                                            </th>
                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2 rounded-tr">
                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl text-center">
                                                                    {t("download")}
                                                                </Typography>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            {item.contracts.map((contract, contractIndex) => (
                                                                <tr key={contract.id} className={`${contractIndex % 2 ? "bg-blue-gray-50/75" : "bg-white"} hover:bg-gray-200 transition-all`}>
                                                                    <td className="p-2">
                                                                        <Link to={`/download/${contract.picture.id}`}>
                                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                <Avatar src={contract.picture.minPath} variant="rounded" />
                                                                            </Typography>
                                                                        </Link>
                                                                    </td>
                                                                    <td className="p-2">
                                                                        <Link to={`/download/${contract.picture.id}`}>
                                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                {t("articleJpeg", { height: contract.picture.height, width: contract.picture.width })}
                                                                            </Typography>
                                                                        </Link>
                                                                    </td>
                                                                    <td className="p-2">
                                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                                            {contract.picture.id}
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="p-4">
                                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                                            1
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="p-2 text-center">
                                                                        <IconButton
                                                                            onClick={() => getContratLicence(contract.id, contract.picture.id)}
                                                                            variant="outlined"
                                                                            color="orange"
                                                                            className="rounded-full text-orange-400 hover:text-gray-400 hover:border-gray-400 transition-all"
                                                                        >
                                                                            <FontAwesomeIcon icon="file-pdf" />
                                                                        </IconButton>
                                                                    </td>
                                                                    <td className="p-2 text-center">
                                                                        <a href={`/download/${contract.picture.id}`}>
                                                                            <IconButton
                                                                                variant="outlined"
                                                                                color="orange"
                                                                                className="rounded-full text-orange-400 hover:text-gray-400 hover:border-gray-400 transition-all"
                                                                            >
                                                                                <FontAwesomeIcon icon="download" />
                                                                            </IconButton>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationCallNextOne pagination={history.pagination} callNextPicture={callNextPicture} />
                </>
            ) : (
                <Alert className="w-2/3 mx-auto my-4 bg-gray-300 text-black text-sm text-center">{t("nosale")}</Alert>
            )}
        </>
    );
}
