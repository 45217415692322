import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function LightButtonResponsive({ text, icon, isWithColor }: { text: string, icon: any, isWithColor: boolean }) {
    const { t } = useTranslation();

    return (
        <>
            <button
                className={`border-2 bg-gray-200/30 hover:bg-white border-transparent inline w-auto py-1 px-4 rounded-full hover:shadow-lg transition-all text-center cursor-pointer ${isWithColor ? "text-gray-600 hover:text-gray-700" : "text-white hover:text-black"}`}
                style={{
                    transition: "all .25s ease-in-out",
                }}
            >
                <span className="hidden md:flex">{t(text)}</span>
                <FontAwesomeIcon icon={icon} className="md:hidden text-white hover:text-black" />
            </button>
        </>
    );
}
