import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import LogoE from "../../assets/images/logos/SVG/logo_e.svg";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import Footer from "../../components/Footer/Footer";
import { useMotionValueEvent, useScroll, motion } from "framer-motion";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";

const data = [
    {
        label: "generalites",
        value: "generalites",
        describe: "generalites_d",
        icon: "",
        desc: [
            {
                title: "Lorem Ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem Ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
    {
        label: "accountmanage",
        value: "accountmanage",
        describe: "accountmanage_d",
        icon: "user-gear",
        desc: [
            {
                title: "Lorem Ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem Ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
    {
        label: "portfolioprestations",
        value: "portfolioprestations",
        describe: "portfolioprestations_d",
        icon: "hands-holding-child",
        desc: [
            {
                title: "Lorem Ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
    {
        label: "picturesRightsWriter",
        value: "picturesRightsWriter",
        describe: "picturesRightsWriter_d",
        icon: "images",
        desc: [
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
    {
        label: "adspay",
        value: "adspay",
        describe: "adspay_d",
        icon: "credit-card",
        desc: [
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
    {
        label: "feedbacks",
        value: "feedbacks",
        describe: "feedbacks_d",
        icon: "comment-dots",
        desc: [
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
            {
                title: "Lorem ipsum",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis felis ligula. Duis dignissim eu nisl vel lacinia. Nam quis semper dolor, vel fringilla elit. Pellentesque vehicula blandit lacus, vitae congue nisi tempus at. Maecenas convallis ipsum quam, in porttitor odio ultrices vel. Suspendisse porttitor pretium lacinia. Phasellus sollicitudin, libero et lacinia sodales, sem quam semper diam, quis gravida diam mi nec nibh. Donec ullamcorper vulputate euismod.",
            },
        ],
    },
];

export default function Aide() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("help")}`;
    const [open, setOpen] = useState("");
    const [searchBar, setSearchBar] = useState(false);
    const [hidden, setHidden] = useState(false);

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    const handleOpen = (index: string) => {
        if (open === index) return setOpen("");
        setOpen(index);
    };

    return (
        <div className="min-h-full relative">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="flex flex-col h-full bg-white -mt-4 pb-[340px] md:pb-36">
                <motion.div
                    variants={{
                        visible: { top: "48px", paddingTop: "24px" },
                        hidden: { top: "0px", paddingTop: "6px" },
                        barsInactive: { top: "48px", paddingTop: "22px" },
                        barsActive: { top: "102px", paddingTop: "18px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200"
                >
                    <TitlePage mt={0} mb={0}>
                        {t("helpContact")}
                    </TitlePage>
                </motion.div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6">
                        <div className="flex flex-col h-full w-full md:w-2/3 px-4 md:px-0 text-justify gap-4 pt-4 max-w-7xl mx-auto">
                            <div className="flex flex-col md:flex-row flex-wrap">
                                {data.map(({ label, value, describe, icon }, index: number) => (
                                    <Link to={value} smooth={true} key={value} className={`w-full md:w-1/2 ${index % 2 === 0 ? "md:pr-4" : "md:pl-4"} py-2`}>
                                        <div className="border flex flex-col gap-2 h-full w-full px-4 py-4 rounded-lg items-start justify-start bg-white hover:bg-gray-100 transition-all cursor-pointer">
                                            {value === "generalites" ? (
                                                <LogoE height={40} />
                                            ) : (
                                                <FontAwesomeIcon icon={icon as any} className="text-orange-500 text-4xl" />
                                            )}
                                            <h2 className="text-lg font-bold text-black">{t(label)}</h2>
                                            <p className="text-xs">{t(describe)}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>

                            {data.map(({ value, desc, icon }) => (
                                <>
                                    <h2 className="text-2xl mt-6 flex gap-3" id={value}>
                                        {value === "generalites" ? (
                                            <LogoE height={30} />
                                        ) : (
                                            <FontAwesomeIcon icon={icon as any} className="text-orange-500 text-3xl" />
                                        )}
                                        {t(value)}
                                    </h2>
                                    {desc.map(({ title, desc }, index) => (
                                        <Accordion key={title} open={open === value + index} id={title}>
                                            <AccordionHeader
                                                onClick={() => handleOpen(value + index)}
                                                className="flex justify-between items-center w-full relative"
                                            >
                                                <span className="flex gap-2 font-bold text-black">{t(title)}</span>
                                                <FontAwesomeIcon
                                                    icon={open === value + index ? "angle-up" : "angle-down"}
                                                    className="absolute right-0 bottom-4"
                                                />
                                            </AccordionHeader>
                                            <AccordionBody>{t(desc)}</AccordionBody>
                                        </Accordion>
                                    ))}
                                </>
                            ))}

                            <h2 className="text-2xl  mt-10 flex gap-3" id="contact">
                                <FontAwesomeIcon icon="envelope" className="text-orange-500" />
                                {t("contact")}
                            </h2>
                            <span className="flex gap-2 text-lg font-bold text-black">{t("dontfountresponse")}</span>
                            <div className=" gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("dontfountresponse_d") }}></div>

                            <div className="flex flex-col gap-2 mt-4">
                                <span className="flex gap-2 text-lg font-bold text-black">{t("mentionslegales")}</span>
                                <span className="flex gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("mentionslegales_d") }}></span>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
