import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip, Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DefaultProfilePicture from "../../assets/images/elements/default-profile.png";
import { RootState } from "../../services/redux/store";
import InputPlace from "../Form/Input/InputPlace";
import InputCountry from "../Form/Input/InputCountry";
import { useState } from "react";
import IntlTelInput from "react-intl-tel-input";

export default function CardMe() {
    const { t } = useTranslation();
    const [uuid, setUuid] = useState<string>("");
    const general = useSelector((state: RootState) => state.general);

    return (
        <>
            <div className="flex flex-col gap-4 rounded-3xl bg-gray-100 w-full px-6 py-4">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
                    <h1 className="text-lg font-bold">{t("payementdata")}</h1>
                    <div className="flex flew-row items-center gap-2 w-fit">
                            <Avatar size="sm" src={general?.me?.portfolio?.picture ?? DefaultProfilePicture} />
                            <div className="flex flex-col">
                                <p className="text-xs font-bold flex flex-row gap-2">
                                    <span>
                                        {general?.me?.portfolio?.name ?? "" }
                                    </span>
                                    {general?.me?.verified && <FontAwesomeIcon icon="check-circle" className="text-gray-500" />}
                                </p>
                                <Typography variant="small" color="blue-gray" className="font-normal text-xs text-gray-500">
                                    {t("photographer")}
                                </Typography>
                            </div>
                        </div>
                </div>
                <div className="flex flex-col gap-4 rounded-lg bg-gray-100 w-full">
                    <div className="w-full">
                        <Input
                            type="text"
                            color="purple"
                            label={t("society")}
                            name="company"
                            value={general?.me?.company}
                            className="bg-white"
                            disabled={true}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <Input
                            type="text"
                            color="purple"
                            label={t("firstname")}
                            name="firstname"
                            value={general?.me?.firstname}
                            className="bg-white"
                            disabled={true}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("name")}
                            name="lastname"
                            value={general?.me?.lastname}
                            className="bg-white"
                            disabled={true}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <div className="w-full md:w-1/3">
                            <InputCountry
                                onSelect={() => ({})}
                                disabled={true}
                                title="country"
                                value={general?.me?.country?.frLabel ?? ""}
                            />
                        </div>
                        <div className="w-full md:w-2/3">
                            <InputPlace
                                onSelect={() => ({})}
                                uuid={uuid}
                                disabled={true}
                                title="adress"
                                value={general?.me?.place}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <IntlTelInput
                            inputClassName="z-50 peer w-full h-full bg-white text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 focus:py-2 rounded-[7px] border-blue-gray-200 focus:border-deep-purple-500"
                            defaultCountry="ch"
                            onPhoneNumberChange={() => ({})}
                            value={general?.me?.phone}
                            disabled={true}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("mail")}
                            name="email"
                            value={general?.me?.email}
                            className="bg-white"
                            required
                            disabled={true}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <div className="w-full md:w-3/4">
                            <Input
                                type="text"
                                color="purple"
                                label={t("iban")}
                                name="iban"
                                value={general?.me?.iban}
                                containerProps={{ className: "min-w-[30px]" }}
                                className="bg-white"
                                disabled={true}
                            />
                        </div>
                        <div className="w-full md:w-1/4">
                            <Input
                                type="text"
                                color="purple"
                                label={t("bic")}
                                name="bic"
                                value={general?.me?.bic}
                                className="bg-white"
                                containerProps={{ className: "min-w-[30px]" }}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <p className="font-normal text-xs" dangerouslySetInnerHTML={{ __html: t("verifyYourInformations")}}>

                </p>
            </div>
        </>
    );
}
