import Footer from "../../components/Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import GradientButton from "../../components/Form/Button/GradientButtonBig";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import EmailVerficiationAPI from "../../services/API/Clients/EmailVerificationAPI";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { updateMe } from "../../services/redux/actions/generalActions";
import { handleError } from "../../services/Errors/handleErrors";
import { Storage } from "../../services/storage";

const token = Storage.getToken();

export default function EmailConfirmation() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("inscription_confirmation")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const dispatch = useDispatch();
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body.picture);
                setLoading(false);
            } else {
                handleError(response);
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const uuid = searchParams.get("token");

    useEffect(() => {
        const fetchData = async () => {
            if (!uuid) {
                setSuccess(false);
                setLoading(false);
                return;
            }
            const response = await EmailVerficiationAPI.verifMail(uuid)
            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setLoading(false);
                if (token) {
                    const me = await UserAPI.me();
                    if (me.status === 200 || me.status === 201) {
                        dispatch(updateMe(me.body))
                    }
                }
            } else {
                setSuccess(false);
                setLoading(false);
                handleError(response);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="min-h-full">
            <Loader loading={loading} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div
                className="hidden md:block h-full min-h-screen"
                style={{
                    backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <div className="relative min-h-screen flex justify-start items-start">
                <div className="relative w-screen min-h-screen md:min-h-fit mt-0 mb-0 md:h-fit md:w-2/3 xl:w-1/3 bg-white rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <FontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-2 text-center">{t("email_confirmation")}</h1>
                    <p className="text-center text-gray-500 text-sm gap-2 mb-4 mt-2" dangerouslySetInnerHTML={{ __html: success ? t("email_confirmation_confirmed") : t("email_confirmation_error") }}></p>
                    <Link to="/" className="w-full mx-auto">
                        <GradientButton text="home" />
                    </Link>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
