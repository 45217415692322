import PictureAlone from "./PictureAlone";
import { PictureSearch } from "../../Interfaces/PictureType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Storage } from "../../../services/storage";
import Gallery from "react-photo-gallery";

export default function PictureWithoutInfinite({ pictures, heart = true }: Readonly<{ pictures: PictureSearch[]; heart?: boolean }>) {
    const { t } = useTranslation();
    const [printNsfw, setPrintNsfw] = React.useState(false);

    const handleOpenNsfw = (arg: boolean) => {
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    };

    useEffect(() => {
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    }, []);

    return (
        <div>
            {pictures.length !== 0 ? (
                pictures &&
                pictures.length > 0 && (
                    <Gallery
                        photos={pictures.map((item) => ({
                            src: item.minPath,
                            width: item.width,
                            height: item.height,
                            alt: item.id,
                        }))}
                        renderImage={(props) => (
                            <PictureAlone
                                key={props.photo.src}
                                width={props.photo.width}
                                height={props.photo.height}
                                margin={props.margin}
                                index={props.index}
                                data={pictures[props.index]}
                                heart={heart}
                                printNsfw={printNsfw}
                                handleChangeNsfw={handleOpenNsfw}
                                pictures={pictures}
                                loadMore={() => { }}
                                paddingIsZero={true}
                            />
                        )}
                    />
                )
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
                    <Typography className="text-base text-gray-500 font-sans mt-4 text-center mb-4">{t("noPictures")}</Typography>
                </div>
            )}
        </div>
    );
}
