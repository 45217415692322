import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Input, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButton";
import { ContractType } from "../../components/Interfaces/ContractType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import ContractsAPI from "../../services/API/Clients/ContractAPI";
import { updateBasePrice, updateContract, updateName } from "../../services/redux/actions/contractActions";
import Declarations from "./components/Declarations";
import DispoSousLicence from "./components/DispoSousLicence";
import Dispositions from "./components/Dispositions";
import Generalites from "./components/Generalites";
import HeaderImg from "./components/HeaderImg";
import Limitation from "./components/Limitation";
import PropIntellectuel from "./components/PropIntellectuel";
import Resiliation from "./components/Resiliation";
import Restrictions from "./components/Restrictions";
import UseSousLicence from "./components/UseSousLicence";
import UsersLicence from "./components/UsersLicence";
import WhoSousLicence from "./components/WhoSousLicence";
import { handleError } from "../../services/Errors/handleErrors";

export default function ContractsEdit() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("addcontract")}`;
    const [searchBar, setSearchBar] = React.useState<boolean>(false);
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [followChangeUntil, setFollowChangeUntil] = React.useState<string>("none");
    const [isInError, setIsInError] = React.useState<boolean>(false);
    const [openers, setOpeners] = React.useState<{
        limit: boolean,
        exclusivity: boolean,
        commercial: boolean
    }>({
        limit: false,
        exclusivity: false,
        commercial: false
    });

    const handleOpenerLimit = () => setOpeners({ ...openers, limit: !openers.limit });
    const handleOpenerExclu = () => setOpeners({ ...openers, exclusivity: !openers.exclusivity });
    const handleOpenerComm = () => setOpeners({ ...openers, commercial: !openers.commercial });

    const submitForm = async (e: any) => {
        e.preventDefault()
        if (!id || id === undefined || id === null) return;

        const datas: ContractType = {
            name: contract.name,
            available: contract.available,
            exclusive: contract.exclusive,
            limited: contract.limited,
            commercialUse: contract.commercialUse,
            autonomous: contract.autonomous,
            branding: contract.branding,
            forSale: contract.forSale === "false" ? false : true,
            digitalModels: contract.digitalModels,
            print: contract.print,
            useByEmployerClient: contract.useByEmployerClient,
            useBySubcontractor: contract.useBySubcontractor,
            userCount: contract.userCount,
            basePrice: contract.basePrice,
        }
        if (followChangeUntil !== "none")
            datas.until = contract.until
        if (contract.available === "LIMITED")
            datas.originalCount = contract.originalCount
        if (contract.limited)
            datas.limitedCount = contract.limitedCount
        if (contract.forSale) {
            datas.salePaper = contract.salePaper
            datas.saleDesk = contract.saleDesk
            datas.saleMerch = contract.saleMerch
        }
        if (contract.print)
            datas.printCount = contract.printCount

        const response = await ContractsAPI.putContract(datas, id)
        if (response.status === 200 || response.status === 201) {
            toast.success(t("contract_edited"))
            window.close()
        } else {
            handleError(response);
        }
    }

    const changeName = (name: string) => {
        dispatch(updateName(name))
    }

    const changePrice = (price: number) => {
        dispatch(updateBasePrice(price))
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!id) return;
            const response = await ContractsAPI.getContract(id);
            if (response.status === 200) {
                setLoading(false)
                dispatch(updateContract(response.body))
            } else {
                handleError(response);
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <Loader loading={loading} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <TitlePage><FontAwesomeIcon icon="file-invoice" className="text-orange-500" /> {t("contract")}</TitlePage>
                    <WrapperGrayZone pt="0" px="4">
                        <div className="w-full flex-grow max-w-7xl mx-auto">
                            <HeaderImg setOpeners={setOpeners} />
                            <p className="mt-12  text-xl font-sans">{t("contract_name")}</p>
                            <form onSubmit={submitForm}>
                                <div className="w-full md:w-1/3 mt-2">
                                    <Input
                                        color="orange"
                                        label={t("contract_name")}
                                        onChange={(e) => changeName(e.target.value)}
                                        value={contract.name}
                                        className="bg-white"
                                        required
                                    />
                                </div>
                                <Generalites />
                                <DispoSousLicence openGeneralites={openers.limit} setOpenDescription={handleOpenerLimit} />
                                <UseSousLicence followChangeUntil={followChangeUntil} setFollowChangeUntil={setFollowChangeUntil} openGeneralites={openers.exclusivity} setOpenDescription={handleOpenerExclu} />
                                <Restrictions openGeneralites={openers.commercial} setOpenDescription={handleOpenerComm} isInError={isInError} />
                                <WhoSousLicence />
                                <UsersLicence />
                                <PropIntellectuel />
                                <Resiliation />
                                <Declarations />
                                <Limitation />
                                <Dispositions />
                                <p className="mt-12  text-base font-sans">{t("license_price")}</p>
                                <div className="w-full md:w-1/3 mt-2">
                                    <Input
                                        color="orange"
                                        type="number"
                                        label={t("license_price")}
                                        onChange={(e) => changePrice(Number(e.target.value))}
                                        id="section4"
                                        value={contract.basePrice}
                                        className="bg-white"
                                    />
                                </div>
                                <Typography size="small" color="gray" className="text-sm mt-2">{t("invalid_default_price")}</Typography>
                                <button className="w-fit mx-auto my-4">
                                    <GradientButton text={t("editcontract")} />
                                </button>
                            </form>
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}