import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PictureSearch } from "../../../../components/Interfaces/PictureType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";

export default function ModalSelectPictures({
    open,
    setOpen,
    pictures,
    setPictures,
}: Readonly<{ open: boolean; setOpen: () => void; pictures: { id: string; minPath: string }[]; setPictures: Function }>) {
    const { t } = useTranslation();
    const formData = useSelector((state: any) => state.form);
    const [tmpPictures, setTmpPictures] = useState<PictureSearch[]>([]);

    const handleSelectPictures = (pictureToAdd: PictureSearch) => async () => {
        const oldPictures = tmpPictures;
        if (oldPictures.length > 0) {
            if (oldPictures.find((picture: { id: string; minPath: string }) => picture.id === pictureToAdd.id)) {
                const newPictures = oldPictures.filter((picture: { id: string; minPath: string }) => picture.id !== pictureToAdd.id);
                setTmpPictures(newPictures);
            } else {
                const newPictures = [...oldPictures, pictureToAdd];
                setTmpPictures(newPictures);
            }
        } else {
            const newPictures = [pictureToAdd];
            setTmpPictures(newPictures);
        }
    };

    useEffect(() => {
        if ( pictures.length > 0 && formData.pictures.data.length > 0 && tmpPictures.length === 0) {
            const tmp: PictureSearch[] = []
            pictures.forEach(item => {
                const found = formData.pictures.data.find((element: any) => element.id === item.id);
                if (found) {
                    tmp.push(found);
                }
            })
            setTmpPictures(tmp);
        }
    }, [pictures, formData])

    const handleValidate = () => {
        setPictures(tmpPictures);
        setOpen();
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen overflow-y-auto">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900 text-center">
                    {t("selectPictures")}
                </Dialog.Title>
                <FontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen()} />

                {formData.pictures && formData.pictures.data.length > 0 ? (
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        {formData.pictures.data.map((picture: PictureSearch) => (
                            <button className="aspect-square relative" key={picture.id} onClick={handleSelectPictures(picture)}>
                                <img src={picture.minPath} alt={picture.id} className="object-cover h-full w-full rounded-lg" />
                                {tmpPictures.find((tmpPicture: PictureSearch) => tmpPicture.id === picture.id) && (
                                    <div className="absolute top-0 left-0 w-full h-full bg-black/20 rounded-lg transition-all flex justify-center items-center">
                                        <span className="text-white text-4xl font-bold">✓</span>
                                    </div>
                                )}
                            </button>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <FontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
                        <span className="text-base text-gray-500 font-sans mt-4 text-center mb-4" dangerouslySetInnerHTML={{ __html: t("noPicturesToChoose")}}></span>
                    </div>
                )}

                <div className="flex justify-center gap-4 mt-4">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleValidate()}
                    >
                        {t("validate")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full bg-white border px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
