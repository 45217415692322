import moment from "moment";
import { Galleries } from "../../Interfaces/PortfolioType";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import { Link } from "react-router-dom";

export default function GalerySmall({ data } : Readonly<{ data: Galleries }>) {
    return (
        <Link to={`/gallery/${data.id}`}>
            <div className="w-full rounded overflow-hidden shadow-lg h-full" key={data.id}>
                <div
                    className="w-full h-48 flex relative bg-cover"
                    style={{
                        backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                        backgroundImage: `url(${(data.banner && data.banner.minPath) ?? DefaultBannerEzoom})`,
                    }}
                ></div>
                <div className="px-6 py-4 flex flex-col items-center bg-white">
                    <div className="font-bold text-base mb-2 text-center">{data.title}</div>
                    <p className="text-gray-700 text-sm text-center">
                        {moment().format("DD.MM.YYYY")}
                    </p>
                </div>
            </div>
        </Link>
    );
}
