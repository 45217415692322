import React, { useEffect } from "react";
import GradientButton from "../../../components/Form/Button/GradientButton";
import Description from "./components/Description";
import Galerie from "./components/Galerie";
import Keywords from "./components/Keywords";
import Filigrane from "./components/Filigrane";
import Adult from "./components/Adult";
import Identification from "./components/Identification";
import Licence from "./components/Licence";
import Sell from "./components/Sell";
import { useTranslation } from "react-i18next";
import WhitButton from "../../../components/Form/Button/WhiteButton";
import GalerieAPI from "../../../services/API/Clients/GalleryAPI";
import { toast } from "react-toastify";
import KeywordsAPI from "../../../services/API/Clients/KeywordsAPI";
import UploadAPI from "../../../services/API/Clients/UploadAPI";
import { Storage } from "../../../services/storage";
import { useSelector } from "react-redux";
import UserAPI from "../../../services/API/Clients/UserAPI";
import WrittenDocumentsAPI from "../../../services/API/Clients/WrittenDocuments";
import ModalPreview from "../../../components/Modals/ModalPreview";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../services/Errors/handleErrors";

export default function SideUpload({
    open,
    closeDrawer,
    number,
    bulkIds,
    resetBulk,
    getMyPictures,
    defaultData
}: Readonly<{ open: boolean; closeDrawer: () => void; number: number; bulkIds: string[]; resetBulk: any; getMyPictures: () => void; defaultData?: {
    place?: {
        id?: string,
        label?: string
    }
    date?: string,
} }>) {
    const { t } = useTranslation();
    const myLang = Storage.getLanguage() ?? "fr";
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false);
    const [openers, setOpeners] = React.useState<boolean[]>([false, false, false, false, false]);
    const [optionChoose, setOptionChoose] = React.useState("new");
    const [optionChooseFili, setOptionChooseFili] = React.useState("none");
    const [openModal, setOpenModal] = React.useState(false);
    const [sideChoose, setSideChoose] = React.useState<"bl" | "br" | "full">("bl");
    const [listTags, setListTags] = React.useState<{ value: string; label: string; type: string }[]>([]);
    const [recommandedKeywords, setRecommandedKeywords] = React.useState<{ value: string; label: string }>({ value: "", label: "" });
    const [tagInput, setTagInput] = React.useState("");
    const [optionChooseLicence, setOptionChooseLicence] = React.useState("all-rights-reserved");
    const [optionCat, setOptionCat] = React.useState("");
    const [optionTyp, setOptionTyp] = React.useState("");
    const [identification, setIdentification] = React.useState<{
        people: boolean, 
        properties: boolean,
        writtenContract: boolean
    }>({
        people: false, 
        properties: false,
        writtenContract: false
    });
    const [selectedLicense, setSelectedLicense] = React.useState<any>({});
    const [configIndivOrNot, setConfigIndivOrNot] = React.useState<boolean>(true);
    const [writtenAccords, setWrittenAccords] = React.useState<File[]>([]);
    const [descriptions, setDescriptions] = React.useState<{ titre: string; description: string; lieu: { id: string, label: string }; date: string }[]>([{
        titre: "",
        description: "",
        lieu: {
            id: "",
            label: ""
        },
        date: "",
    }]);
    const [galeries, setGaleries] = React.useState<{ id: string; titre: string; lieu: { id: string, label: string }; date: string }>({
        id: "",
        titre: "",
        lieu: {
            id: "",
            label: ""
        },
        date: "",
    });
    const general = useSelector((state: any) => state.general)
    const [logoFiligrane, setLogoFiligrane] = React.useState<File | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (general?.me?.plan && general?.me?.defaultLicense) {
                setOptionChooseLicence(general.me.defaultLicense);
                if (general.me.enableWatermark === "none")
                    setOptionChooseFili("none")
                else
                    setOptionChooseFili(general.me.defaultWatermark)
                setSideChoose(general.me.enableWatermark)
            } else {
                const response = await UserAPI.me();
                if (response.status === 200 || response.status === 201) {
                    setOptionChooseLicence(response.body.defaultLicense);
                    if (response.body.enableWatermark === "none")
                        setOptionChooseFili("none")
                    else
                        setOptionChooseFili(response.body.defaultWatermark)
                    setSideChoose(response.body.enableWatermark)
                } else {
                    handleError(response);
                }
            }
        }
        fetchData();
    }, [])

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const textMajor = tagInput.charAt(0).toUpperCase() + tagInput.slice(1);
            const myData = {
                value: textMajor,
                label: textMajor,
                type: "fromCustom",
            };
            setListTags([...listTags, myData]);
            setTagInput("");
            setRecommandedKeywords({ value: "", label: "" });
        } else if (event.key === "Tab") {
            if (recommandedKeywords.value === "") return;
            const myData = {
                value: recommandedKeywords.value,
                label: recommandedKeywords.label,
                type: "fromList",
            };
            setListTags([...listTags, myData]);
            setTagInput("");
            setRecommandedKeywords({ value: "", label: "" });
        } else if (event.key === "Backspace" && tagInput === "") {
            setRecommandedKeywords({ value: "", label: "" });
            handleChooseTag();
        } else console.error("error")
    };

    useEffect(() => {
        const prevDescriptions = descriptions;
        const newData = { ...descriptions[0] };
        if (bulkIds.length > 1) {
            for (let i = descriptions.length; i < bulkIds.length; i++) {
                prevDescriptions.push(newData);
            }
        }
        if (bulkIds.length < descriptions.length) {
            prevDescriptions.splice(bulkIds.length, descriptions.length - bulkIds.length);
        } 
        if (prevDescriptions.length === 0) prevDescriptions.push(newData);
        setDescriptions(prevDescriptions);
    }, [bulkIds]);

    const addTagToList = (values: string, labels: string) => {

        const myData = {
            value: values,
            label: labels,
            type: "fromList",
        };
        setListTags([...listTags, myData]);
    };

    const removeTag = (tag: string) => {
        setListTags(listTags.filter((item) => item.label !== tag));
    };

    const handleChangeSelect = (event: any) => {
        setOptionChoose(event.value);
    };

    const handleChangeSelectFili = (event: any) => {
        setOptionChooseFili(event.value);
    };

    const handleModalToggle = () => {
        setOpenModal(!openModal);
    };

    const handleRadio = (arg: "bl" | "br" | "full") => {
        setSideChoose(arg);
    };

    const toggleOpener = (index: number) => () => {
        const newOpeners = [...openers];
        newOpeners[index] = !newOpeners[index];
        setOpeners(newOpeners);
    };

    const handleChangeSelectLicence = (event: any) => {
        setOptionChooseLicence(event);
        // setOptionChooseLicence(event.value);
    };

    const handleSelectCat = (event: any) => {
        setOptionCat(event.value);
    };

    const handleSelectTyp = (event: any) => {
        setOptionTyp(event.value);
    };

    /***************************************************************** */

    const handleChooseTag = async () => {
        if (optionCat === "") return;
        if (tagInput === "") {
            setRecommandedKeywords({ value: "", label: "" });
            return;
        }
        const response = await KeywordsAPI.keywordsAutoCompleteCategory(tagInput, optionCat);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            if (response.body[0] === undefined) {
                setRecommandedKeywords({ value: "", label: "" });
                return;
            }
            setRecommandedKeywords({
                value: response.body[0].enumValue,
                label: myLang === "fr" ? response.body[0].frLabel : response.body[0].enLabel,
            });
        } else {
            handleError(response);
        }
    };

    const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        const list: any = [...descriptions];
        list[index][name] = value;
        setDescriptions(list);
    };

    const handleChangeDescriptionLieu = (id: string, label: string, index: number) => {
        const list: any = [...descriptions];
        list[index].lieu = { id: id, label: label };
        setDescriptions(list);
    };

    const handleChangeGaleries = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setGaleries({ ...galeries, [name]: value });
    };

    const handleChangeGaleriesLieu = (id: string, label: string) => {
        setGaleries({ ...galeries, lieu: { id: id, label: label } });
    };

    const uploadMyPicture = async () => {
        if (isLoading) return;
        setIsLoading(true);
        if (optionCat === "" && optionTyp === "") {
            toast.warning(t("missingcat_type"));
            setIsLoading(false);
            return;
        }

        if (selectedLicense?.id && optionChooseLicence !== "own-license") {
            toast.warning(t("missingownlicence"));
            setIsLoading(false);
            return;
        }

        let responseGalerie
        if (optionChoose === "new" && (galeries.titre !== "" || galeries.lieu.id !== "" || galeries.date !== "")) {
            const dataGalerie = {
                title: galeries.titre,
                ...(galeries.lieu.id && { place: `/api/public/places/${galeries.lieu.id}` }),
                ...(galeries.date && { date: galeries.date }),
            };
            responseGalerie = await GalerieAPI.newGallerie(dataGalerie);
            if (responseGalerie.status === 200 || responseGalerie.status === 201) {
                await setGaleries({ ...galeries, id: responseGalerie.body.id });
            } else {
                handleError(responseGalerie);
            }
        }

        if (optionChooseFili === "logo" && (!logoFiligrane && !general?.me?.logo)) {
            toast.warning(t("missinglogo"));
            setIsLoading(false);
            return;
        }

        if (logoFiligrane) {
            const formData = new FormData();
            formData.append("image", logoFiligrane);
            await UserAPI.uploadLogo(formData, general.me.id);
        }

        let listOfIds: any[] = []
        if (identification.writtenContract && writtenAccords.length > 0) {
            const uploadPromises = writtenAccords.map(async (file) => {
                const formData = new FormData();
                formData.append("document", file);
                const response = await WrittenDocumentsAPI.upload(formData);
                if (response.status === 200 || response.status === 201) {
                    setIsLoading(false);
                    return response.body.id;
                } else {
                    handleError(response);
                }
            });
            const results = await Promise.all(uploadPromises);
            listOfIds = results.filter(id => id !== undefined);
        }

        if ((bulkIds.length === 1 || configIndivOrNot)) {
            const datas = {
                bulkIds: bulkIds,
                request: {
                    ...(descriptions[0].titre && { title: descriptions[0].titre }),
                    ...(descriptions[0].description && { description: descriptions[0].description }),
                    ...(descriptions[0].date && { takeDate: descriptions[0].date }),
                    ...(descriptions[0].lieu.id && { place: `/api/public/places/${descriptions[0].lieu.id}`  }),
                    ...(listTags.length > 0 && {
                        keywords:
                            listTags.map((tag, index: number) => (tag.type === "fromCustom" ? { index: index, customLabel: tag.label } : { index: index, keyword: `/api/keywords/${tag.value}` })),
                    }),
                    ...(optionCat && { category: `/api/categories/${optionCat}` }),
                    ...(optionTyp && { typeStyle: `/api/type-styles/${optionTyp}` }),
                    ...(optionChooseFili !== "none" && { watermarkType: optionChooseFili }),
                    ...(optionChooseFili !== "none" && { watermark: sideChoose }),
                    ...(optionChooseFili === "none" && { watermark: "none" }),
                    nsfw: openers[2],
                    identifiablePeople: identification.people,
                    identifiableProperties: identification.properties,
                    writtenContract: identification.writtenContract,
                    ...(optionChooseLicence && { license: optionChooseLicence }),
                    ...(selectedLicense?.id !== undefined && selectedLicense?.id !== 0 && { referenceContract: `/api/contracts/${selectedLicense.id}` }),
                    ...(selectedLicense?.id !== undefined && selectedLicense?.id !== 0 && selectedLicense?.price && selectedLicense?.price !== selectedLicense?.basePrice && selectedLicense?.price !== 0 && { customContractPrice: Number(selectedLicense.price) }),
                    ...(optionChoose === "new" && responseGalerie && responseGalerie.body.id && { galleries: [{ parent: `/api/public/galleries/${responseGalerie.body.id}` }] }),
                    ...(optionChoose !== "new" && { galleries: [{ parent: `/api/public/galleries/${optionChoose}` }] }),
                    ...(identification.writtenContract && listOfIds.length > 0 && { writtenDocuments: listOfIds.map((id) => `/api/written-documents/${id}`) }),
                },
            };
            const response = await UploadAPI.editPictures(datas);
            if (response.status === 200 || response.status === 201) {
                const responseUpload = await UploadAPI.publish({ bulkIds: bulkIds });
                if (responseUpload.status === 200 || responseUpload.status === 201) {
                    toast.success(t("uploadsuccess"));
                    if (optionChoose === "new") {
                        if (responseGalerie?.body.id)
                            navigate(`/gallery/${responseGalerie.body.id}/edit`)
                        else
                            navigate("/my-portfolio")
                    } else navigate(`/gallery/${optionChoose}/edit`)
                    closeDrawer();
                    resetBulk();
                    getMyPictures();
                    resetData();
                    setIsLoading(false);
                } else {
                    handleError(responseUpload);
                    setIsLoading(false)
                }
            } else if (/Picture [\w._-]+ does not exist/.test(response.body.detail)) {
                toast.error(t("Wait until the picture is uploaded"));
            } else {
                handleError(response);
                setIsLoading(false);
            }
            setIsLoading(false);
        } else
            UplaodByPicture(responseGalerie, listOfIds);
    };

    const UplaodByPicture = async (responseGalerie: any, listOfIds: string[]) => {
        bulkIds.forEach(async (element, index: number) => {
            const datas = {
                bulkIds: [bulkIds[index]],
                request: {
                    ...(descriptions[index].titre && { title: descriptions[index].titre }),
                    ...(descriptions[index].description && { description: descriptions[index].description }),
                    ...(descriptions[index].date && { date: descriptions[index].date }),
                    ...(descriptions[index].lieu.id && { place: `/api/public/places/${descriptions[index].lieu.id}`  }),
                    ...(listTags.length > 0 && {
                        keywords:
                            listTags.map((tag, index: number) => (tag.type === "fromCustom" ? { index: index, customLabel: tag.label } : { index: index, keyword: `/api/keywords/${tag.value}` })),
                    }),
                    category: `/api/categories/${optionCat}`,
                    ...(optionTyp && { typeStyle: `/api/type-styles/${optionTyp}` }),
                    ...(optionChooseFili !== "none" && { watermarkType: optionChooseFili }),
                    ...(optionChooseFili !== "none" && { watermark: sideChoose }),
                    ...(optionChooseFili === "none" && { watermark: "none" }),
                    nsfw: openers[2],
                    identifiablePeople: identification.people,
                    identifiableProperties: identification.properties,
                    writtenContract: identification.writtenContract,
                    ...(optionChooseLicence && { license: optionChooseLicence }),
                    ...(selectedLicense && selectedLicense.id && selectedLicense.id !== undefined && { referenceContract: `/api/contracts/${selectedLicense.id}` }),
                    ...(selectedLicense && selectedLicense.id && selectedLicense.id !== undefined && { customContractPrice: Number(selectedLicense.price) }),
                    ...(optionChoose === "new" && responseGalerie && responseGalerie.body.id && { galleries: [{ parent: `/api/public/galleries/${responseGalerie.body.id}` }] }),
                    ...(optionChoose !== "new" && { galleries: [{ parent: `/api/public/galleries/${optionChoose}` }] }),
                    ...(identification.writtenContract && listOfIds.length > 0 && { writtenDocuments: listOfIds.map((id) => `/api/written-documents/${id}`) }),
                },
            };
            const response = await UploadAPI.editPictures(datas);
            if (response.status === 200 || response.status === 201) {
                const responseUpload = await UploadAPI.publish({ bulkIds: [bulkIds[index]] });
                if (responseUpload.status === 200 || responseUpload.status === 201) {
                    toast.success(t("uploadsuccess"));
                    if (optionChoose === "new") {
                        if (responseGalerie?.body.id)
                            navigate(`/gallery/${responseGalerie.body.id}`)
                        else
                            navigate("/my-portfolio")
                    } else navigate(`/gallery/${optionChoose}`)
                    closeDrawer();
                    resetBulk();
                    getMyPictures();
                    resetData();
                    setIsLoading(false);
                } else {
                    handleError(responseUpload);
                    setIsLoading(false)
                }
            } else if (/Picture [\w._-]+ does not exist/.test(response.body.detail)) {
                toast.error(t("Wait until the picture is uploaded"));
            } else {
                handleError(response);
                setIsLoading(false);
            }
            setIsLoading(false);
        })
        setIsLoading(false);
    };

    const resetData = () => {
        setOpeners([false, false, false, false, false]);
        setDescriptions([{ titre: "", description: "", lieu: { id: "", label: ""}, date: "" }]);
        setGaleries({ id: "", titre: "", lieu: {
            id: "",
            label: ""
        }, date: "" });
        setListTags([]);
        setRecommandedKeywords({ value: "", label: "" });
        setTagInput("");
        setOptionChoose("new");
        setOptionChooseFili("none");
        setOptionChooseLicence("all-rights-reserved");
        setOptionCat("");
        setOptionTyp("");
        setIdentification({
            people: false, 
            properties: false,
            writtenContract: false
        });
        setSelectedLicense({});
        setWrittenAccords([]);
    }

    const handleChangePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {
            setLogoFiligrane(event.currentTarget.files[0]);
        }
    };

    const handleChangeIdentification = (name: string, value: boolean) => {
        setIdentification({ ...identification, [name]: value });
    }

    useEffect(() => {
        if (openers[4] === true && optionChooseLicence !== "own-license") {
            setOptionChooseLicence("own-license")
        }
    }, [openers[4]])

    useEffect(() => {
        if (optionChooseLicence === "own-license" && !openers[4]) {
            const openersCopy = [...openers];
            openersCopy[4] = true;
            setOpeners(openersCopy);
        }
    }, [optionChooseLicence])

    useEffect(() => {
        if (openers[4] === true && sideChoose !== "full") {
            setSideChoose("full")
        }
    }, [openers[4]])

    useEffect(() => {
        if (sideChoose === "full" && !openers[4]) {
            const openersCopy = [...openers];
            openersCopy[4] = true;
            setOpeners(openersCopy);
        }
    }, [sideChoose])

    useEffect(() => {
        if (defaultData) {
            setDescriptions([
                {
                    titre: "",
                    description: "",
                    lieu: {
                        id: defaultData.place?.id ?? "",
                        label: defaultData.place?.label ?? "",
                    },
                    date: "",
                },
            ]);
        }
    }, [defaultData]);


    return (
        <div className={`${open ? "w-full md:w-1/3 flex flex-col" : "w-0 hidden"} transition bg-gray-100 shadow -mt-2 flex-shrink flex-basis-0 pt-4`}>
            <ModalPreview open={openModal} setOpen={handleModalToggle} type={sideChoose} />
            <div className="px-4">
                <h1 className="text-center mt-4 text-xl text-black">
                    {t("editandpublish")} <span className="text-orange-500">{t("pictures", { count: number })}</span>
                </h1>
                <Description
                    openDescription={openers[0]}
                    toggleOpenDescription={toggleOpener(0)}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    isIndivOrNot={configIndivOrNot}
                    setConfigIndivOrNot={setConfigIndivOrNot}
                    handleChange={handleChangeDescription}
                    handleChangeDescriptionLieu={handleChangeDescriptionLieu}
                    bulkIds={bulkIds}
                />
                <Galerie
                    handleChangeSelect={handleChangeSelect}
                    openGalerie={openers[1]}
                    optionChoose={optionChoose}
                    toggleOpenGalerie={toggleOpener(1)}
                    galeries={galeries}
                    handleChange={handleChangeGaleries}
                    handleChangeGaleriesLieu={handleChangeGaleriesLieu}
                />
                <Keywords
                    addTag={addTag}
                    listTags={listTags}
                    addTagToList={addTagToList}
                    removeTag={removeTag}
                    optionCat={optionCat}
                    handleSelectCat={handleSelectCat}
                    optionTyp={optionTyp}
                    handleSelectTyp={handleSelectTyp}
                    recommandation={recommandedKeywords}
                    setRecommandedKeywords={setRecommandedKeywords}
                    tagInput={tagInput}
                    setTagInput={setTagInput}
                />
                <Filigrane
                    handleChangeSelectFili={handleChangeSelectFili}
                    handleModalToggle={handleModalToggle}
                    handleRadio={handleRadio}
                    sideChoose={sideChoose}
                    optionChooseFili={optionChooseFili}
                    fileLogo={logoFiligrane}
                    handleChangePicture={handleChangePicture}
                    general={general}
                />
                <Adult filterAdult={openers[2]} handleFilterAdult={toggleOpener(2)} />
                <Identification openIdentifiable={openers[3]} toggleOpenIdentifiable={toggleOpener(3)} identification={identification} setIdentification={handleChangeIdentification} writtenAccords={writtenAccords} setWrittenAccords={setWrittenAccords} />
                <Licence optionChoose={optionChooseLicence} handleChangeLicence={handleChangeSelectLicence} />
                <Sell openSell={openers[4]} toggleOpenSell={toggleOpener(4)} selectedLicense={selectedLicense} setSelectedLicense={setSelectedLicense} general={general} />
            </div>
            <div className="sticky bottom-0 mb-20 z-20 bg-gray-100 w-full py-4 justify-around flex flex-row">
                <WhitButton name="cancel" onClick={closeDrawer} />
                <button onClick={uploadMyPicture}>
                    <GradientButton text={t("addpics", { count: number })} isLoading={isLoading} />
                </button>
            </div>
        </div>
    );
}
