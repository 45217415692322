import React from "react";

export function DisplayText({ text }: { text: string }) {
    return (
        <div className="text-display">
            {text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
}