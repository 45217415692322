import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import AutosizeInput from "react-input-autosize";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateName, updatePicture } from "../../../../services/redux/actions/formAction";
import { useEffect, useRef, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";

import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import ModalChangeBackgroundPortfolio from "./ModalChangeBackgroundPortfolio";
import SharingBox from "../../../../components/Box/SharingBox";
import ModalChangePlanPro from "../../../../components/Modals/ModalChangePlanPro";
import { RootState } from "../../../../services/redux/store";
import PublishPortfolioButton from "../PublishButton";
import { Link } from "react-router-dom";

export default function DesktopHeader({
    bgPosition,
    setBgPosition,
    actualUrl,
    handleLinkClick,
    handleSubmit,
    handleSubmitWithoutPublish,
}: Readonly<{
    bgPosition: number;
    setBgPosition: Function;
    actualUrl: string;
    handleLinkClick: Function;
    handleSubmit: () => void;
    handleSubmitWithoutPublish: Function;
}>) {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [tmpPicture, setTmpPicture] = useState<string>("");
    const formData = useSelector((state: RootState) => state.form);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalUpgrade, setOpenModalUpgrade] = useState<boolean>(false);
    const general = useSelector((state: RootState) => state.general);
    const parentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputWidth, setInputWidth] = useState('100px');

    const handleButtonClick = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (!selectedFile) return;
        dispatch(updatePicture(selectedFile));
        setTmpPicture(URL.createObjectURL(selectedFile));
    };

    const handleChangeName = (e: any) => {
        dispatch(updateName(e.target.value));
    };

    const redirectionAfterCancel = () => {
        const currentUrl = window.location.pathname.split("/")[3];
        if (currentUrl === "photos") return `${formData.path !== "" ? `/${formData.path}/photos` : `/portfolio/${formData.id}/photos`}`;
        else if (currentUrl === "about") return `${formData.path !== "" ? `/${formData.path}/about` : `/portfolio/${formData.id}/about`}`;
        else if (currentUrl === "performances") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else if (currentUrl === "addPerf") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else if (currentUrl === "performance") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else return `${formData.path !== "" ? `/${formData.path}/photos` : `/portfolio/${formData.id}/photos`}`;
    };

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setInputWidth(`calc(${parentHeight}px - 60px)`);
        }
    }, [window.innerWidth, window.outerWidth]);

    return (
        <>
            <ModalChangePlanPro open={openModalUpgrade} setOpen={() => setOpenModalUpgrade(!openModalUpgrade)} />
            <ModalChangeBackgroundPortfolio open={openModal} setOpen={() => setOpenModal(!openModal)} />
            <div
                className="hidden md:flex relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - bgPosition}%`,
                    backgroundImage: `url(${
                        formData.backgroundForModif && formData.backgroundForModif.minPath !== "" && formData.backgroundForModif.minPath
                            ? formData.backgroundForModif.minPath
                            : formData.banner ?? DefaultBannerEzoom
                    })`,
                }}
            >
                <div
                    ref={parentRef}
                    style={{ height: "calc(100% - 60px)", width: "40px" }}
                    className={`absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center ${((formData.backgroundForModif && formData.backgroundForModif.minPath !== "" && formData.backgroundForModif.minPath) || (formData.banner)) ? "opacity-100" : "opacity-0"}`}
                >
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={bgPosition}
                        onChange={(e) => setBgPosition(Number(e.target.value))}
                        ref={inputRef}
                        style={{ width: inputWidth }}
                        className="-rotate-90 transparent h-[4px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                        id="customRange1"
                    />
                </div>
                <div style={{ height: "40px" }} className="absolute bottom-2 right-2 flex flex-col items-center justify-center">
                    <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer" onClick={() => setOpenModal(true)}>
                        <FontAwesomeIcon icon="pen" />
                    </IconButton>
                </div>
                <div className="absolute left-8 -bottom-20 h-40 w-40">
                    <div className="flex flex-row gap-4">
                        <div className="w-20 h-20 md:w-40 md:h-40 aspect-square bg-white rounded-full">
                            <img
                                alt="vector"
                                className="w-full h-full min-w-[160px] object-cover object-center rounded-full border-4 border-white"
                                src={tmpPicture !== "" ? tmpPicture : formData.picture ?? DefaultPictureEzoom}
                            />
                        </div>
                        <div className="absolute left-0 bottom-0 flex flex-col justify-center">
                            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                            <FontAwesomeIcon
                                icon="pen"
                                className="text-black bg-white hover:bg-gray-50 transition-all text-sm p-3 rounded-full ring ring-gray-500/20 cursor-pointer"
                                onClick={handleButtonClick}
                            />
                        </div>
                        <div className="flex flex-col justify-center mt-16">
                            <Typography className="text-lg font-bold w-40">
                                <AutosizeInput
                                    type="text"
                                    value={formData.name}
                                    placeholder="Titre"
                                    onChange={handleChangeName}
                                    className="border-b border-b-gray-500"
                                />
                            </Typography>
                            <div className="flex flex-row gap-2 mt-1.5">
                                {general?.me?.plan === "PRO" && (
                                    <Chip
                                        className="w-fit h-fit bg-black text-white"
                                        color="purple"
                                        variant="ghost"
                                        value={t("pro")}
                                        icon={<FontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                                        size="sm"
                                    />
                                )}
                                {general?.me?.plan === "PREMIUM" && (
                                    <Chip
                                        className="w-fit h-fit bg-white text-black border border-black"
                                        color="orange"
                                        variant="ghost"
                                        value={t("premium")}
                                        icon={<FontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                                        size="sm"
                                    />
                                )}
                                <Popover placement="bottom">
                                    <PopoverHandler>
                                        <Chip
                                            className="w-fit h-fit cursor-pointer"
                                            color="purple"
                                            variant="ghost"
                                            value={`${formData.subscriberCount ?? 0} ${t("subs")}`}
                                            icon={<FontAwesomeIcon icon="users" className="h-3 ml-0.5" />}
                                            size="sm"
                                        />
                                    </PopoverHandler>
                                    <PopoverContent>
                                        <div className="flex flex-row gap-2 items-center">
                                            {formData?.repartition && (formData.repartition.users !== 0 || formData.repartition.photographers !== 0) ? (
                                                <PieChart
                                                    data={[
                                                        { title: t("users"), value: formData.repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                                        { title: t("photographs"), value: formData.repartition.photographers ?? 0, color: "rgb(98, 81, 153)" },
                                                    ]}
                                                    style={{ width: "100px", height: "100px" }}
                                                />
                                            ) : (
                                                <PieChart
                                                    data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]}
                                                    style={{ width: "100px", height: "100px" }}
                                                />
                                            )}
                                            <div className="flex flex-col gap-2">
                                                <div className="flex flex-row gap-2 items-center">
                                                    <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                                    <span>{t("users")}</span>
                                                    <span>{formData.repartition.users ?? 0}%</span>
                                                </div>
                                                <div className="flex flex-row gap-2 items-center">
                                                    <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                                    <span>{t("photographs")}</span>
                                                    <span>{formData.repartition.photographers ?? 0}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                                {(formData.avgNote !== -1 && formData.features.actions.testimonial) && (
                                    <Chip
                                        className="w-fit h-fit"
                                        color="purple"
                                        variant="ghost"
                                        value={`${formData.avgNote === -1 ? t("nonote") : formData.avgNote.toFixed(2)}`}
                                        icon={<FontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                                        size="sm"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden md:flex flex-row flex-wrap ml-auto w-1/2 xl:w-full xl:flex-nowrap justify-end gap-2 mt-2 px-4 pt-0">
                {!formData.published && (
                    <PublishPortfolioButton data={formData} onPublish={handleSubmit} />
                )}
                <button onClick={() => handleSubmitWithoutPublish()} className="pt-0.5 text-base whitespace-nowrap">
                    <GradientButton text={t("save")} />
                </button>
                <Link to={`/upload`}>
                    <button className="border inline w-fit hover:text-black py-2 mt-0.5 px-4 rounded-full bg-white hover:bg-gray-50 transition-all  shadow hover:shadow-lg hover:rounded-full">
                        <span className="flex">{t("importPictures")}</span>
                    </button>
                </Link>
                <a href={redirectionAfterCancel()}>
                    <button className="border inline w-fit hover:text-black py-2 mt-0.5 px-4 rounded-full bg-white hover:bg-gray-50 transition-all  shadow hover:shadow-lg hover:rounded-full">
                        <span className="flex">{t("cancel")}</span>
                    </button>
                </a>
                {formData.public && (
                    <SharingBox />
                )}
            </div>

            <div className="hidden md:flex border-b border-gray-200 w-full bg-white mt-16">
                <ul className="flex flex-row flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 justify-center w-full">
                    <li className="mr-2 h-14">
                        <button
                            onClick={() => handleLinkClick("/portfolio/edit/photos")}
                            className={`${
                                actualUrl === "photos" ? "border-orange-400 text-orange-400" : "border-transparent hover:text-gray-600 hover:border-gray-300"
                            } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                        >
                            <FontAwesomeIcon icon="images" />
                            <span className="flex">{t("photos")}</span>
                            <div
                                className={`${
                                    actualUrl === "photos"
                                        ? "text-orange-700 bg-white-100 border border-orange-300"
                                        : "text-gray-500 bg-white-100 border border-gray-500"
                                } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                            >
                                <div className="text-xs font-normal leading-none max-w-full flex-initial">{formData.pictures.pagination.totalItems ?? 0}</div>
                            </div>
                        </button>
                    </li>
                    <li className="mr-2 h-14">
                        <button
                            onClick={() => handleLinkClick("/portfolio/edit/about")}
                            className={`${
                                actualUrl === "about" ? "border-purple-400 text-purple-400" : "border-transparent hover:text-gray-600 hover:border-gray-300"
                            } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 h-14 rounded-t-lg group`}
                        >
                            <FontAwesomeIcon icon="circle-info" />
                            <span className="flex">{t("about")}</span>
                        </button>
                    </li>
                    {(general?.me?.plan === "USER" || general?.me?.plan === "PHOTOGRAPHER" || general?.me?.plan === "PREMIUM") ? (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => setOpenModalUpgrade(true)}
                                className={`${
                                    actualUrl === "performances" || actualUrl === "addPerf" || actualUrl === "performance"
                                        ? "border-purple-400 text-purple-400"
                                        : "border-transparent text-gray-400"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 h-14 rounded-t-lg group`}
                            >
                                <FontAwesomeIcon icon="list-check" />
                                <span className="flex">{t("performances")}</span>
                            </button>
                        </li>
                    ) : (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick("/portfolio/edit/performances")}
                                className={`${
                                    actualUrl === "performances" || actualUrl === "addPerf" || actualUrl === "performance"
                                        ? "border-purple-400 text-purple-400"
                                        : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 h-14 rounded-t-lg group`}
                            >
                                <FontAwesomeIcon icon="list-check" />
                                <span className="flex">{t("performances")}</span>
                                <div
                                    className={`${
                                        actualUrl === "performances"
                                            ? "text-purple-700 bg-white-100 border border-purple-300"
                                            : "text-gray-500 bg-white-100 border border-gray-500"
                                    } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                >
                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">{formData.services.pagination.totalItems ?? 0}</div>
                                </div>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
