import GradientButton from "../../../components/Form/Button/GradientButton";
import InputError from "../../../components/Form/Input/InputError";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import AlertCloser from "../../../components/Form/Alert/AlertCloser";
import UserAPI from "../../../services/API/Clients/UserAPI";
import ModalAlert from "../../../components/Modals/ModalAlert";
import { useSelector, useDispatch } from "react-redux";
import { Storage } from "../../../services/storage";
import { v4 as uuidv4 } from "uuid";
import InputCountry from "../../../components/Form/Input/InputCountry";
import InputPlaceWithValue from "../../../components/Form/Input/InputPlaceWithValue";
import InputPhone from "../../../components/Form/Input/InputPhone";
import EmailVerficiationAPI from "../../../services/API/Clients/EmailVerificationAPI";
import PhoneVerficiationAPI from "../../../services/API/Clients/PhoneVerification";
import { updateMe } from "../../../services/redux/actions/generalActions";
import { RootState } from "../../../services/redux/store";
import moment from "moment";
import { handleError } from "../../../services/Errors/handleErrors";

function ColorPhoto(pathname: string) {
    if (pathname === "particulier" ) return "text-white bg-orange-400 border-transparent";
    return pathname === "entreprise"
        ? "bg-white text-gray-500 border-gray-400 border-r-transparent hover:text-orange-500 hover:border-orange-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500 hover:z-10";
}

function ColorGrapher(pathname: string) {
    if (pathname === "entreprise") return "text-white bg-orange-400 border-transparent";
    return pathname === "particulier"
        ? "bg-white text-gray-500 border-gray-400 border-l-transparent hover:text-orange-500 hover:border-orange-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500";
}

export default function Data() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = React.useState(false);
    const [uuid, setUuid] = React.useState<string>("");
    const [onglet, setOnglet] = React.useState("particulier");
    const general = useSelector((state: RootState) => state.general);
    const [listDisabled, setListDisabled] = React.useState(false);
    const myId = Storage.getId();
    const [alreadyVerified, setAlreadyVerified] = React.useState(false);
    const [datas, setDatas] = React.useState({
        id: "",
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        adress: {
            id: "",
            label: ""
        },
        birth: "",
        email: "",
        phone: "",
        iban: "",
        swiftbic: "",
    });

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const onChange = (arg: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (arg.target.name === "adress") {
            setDatas({
                ...datas,
                adress: {...datas.adress, id: arg.target.value },
            });
        } else
            setDatas({
                ...datas,
                [arg.target.name]: arg.target.value,
            });
    }

    const getDatas = async () => {
        if (!myId) return
        if (general?.me?.company && general.me.company !== "")
            setOnglet("entreprise")
        const response = await UserAPI.getUser(myId)
        if (response.status === 200 || response.status === 201) {
            setDatas({
                id: myId,
                company: response.body.company ?? "",
                firstname: response.body.firstname,
                lastname: response.body.lastname,
                country: response.body?.country?.code ?? "",
                adress: {
                    id: response.body?.place?.id ?? "",
                    label: response.body?.place?.label ?? ""
                },
                birth: response.body.birthdate ?? "",
                email: response.body.email ?? "",
                phone: response.body.phone ?? "",
                iban: response.body.iban ?? "",
                swiftbic: response.body.bic ?? "",
            })
            if (response.body.phoneVerified)
                setAlreadyVerified(true)
            if (response.body.closedDialogs.length === 0 || !response.body.closedDialogs.includes("personal_datas")) {
                setListDisabled(true)
            } else
                setListDisabled(false)
        } else {
            handleError(response);
        }

    }

    const handleChangeListDisables = async () => {
        if (!general?.me?.id) return;

        const dataListDisables = {
            "dialogToClose": "personal_datas"
        }

        const response = await UserAPI.setDialogs(general?.me?.id, dataListDisables);
        if (response.status === 200 || response.status === 201) {
            setListDisabled(false)
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        getDatas()
    }, [])

    const isValidIBAN = (iban: string): boolean => {
        const ibanPattern = /^[A-Z0-9]+$/;
        // remove spaces
        iban = iban.replace(/ /g, "");
        return ibanPattern.test(iban) && iban.length >= 15 && iban.length <= 34;
    }
    
    const verification = () => {
        const namePattern = /^[a-zA-ZÀ-ÿ\s-]+$/;
        const currentDate = new Date();
    
        if (datas.firstname === "") {
            toast.warning(t("firstnameempty"));
            return true;
        }
        if (!namePattern.test(datas.firstname)) {
            toast.warning(t("firstnameinvalid"));
            return true;
        }
        if (datas.lastname === "") {
            toast.warning(t("lastnameempty"));
            return true;
        }
        if (!namePattern.test(datas.lastname)) {
            toast.warning(t("lastnameinvalid"));
            return true;
        }
        if (datas.birth === "") {
            toast.warning(t("birthempty"));
            return true;
        }
        const birthDate = moment(datas.birth);
        if (!birthDate.isValid() || birthDate.isAfter(currentDate)) {
            toast.warning(t("birthinvalid"));
            return true;
        }
        if (datas.iban !== "" && !isValidIBAN(datas.iban)) {
            toast.warning(t("ibaninvalid"));
            return true;
        }
        return false;
    }

    const handleSubmit = async (e:  React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (verification()) 
            return;
        
        const data = {
            firstname: datas.firstname,
            lastname: datas.lastname,
            ...(datas.company !== "" && { company: datas.company }),
            ...(datas.iban !== "" && { iban: datas.iban.replace(/ /g, "") }),
            ...(datas.swiftbic !== "" && { bic: datas.swiftbic }),
            ...(datas.adress.id !== "" && { place: `/api/public/places/${datas.adress.id}` }),
            ...(datas.country !== "" && { country: `/api/public/countries/${datas.country}` }),
            ...(datas.phone !== "" && { phone: datas.phone.replace(/\s/g, "") }),
            birthdate: datas.birth
        }

        const response = await UserAPI.putUser(data, datas.id);

        if (response.status === 200) {
            toast.success(t("datasupdated"));
            const me = await UserAPI.me();
            if (me.status === 200 || me.status === 201) {
                dispatch(updateMe(me.body))
            }
        } else {
            handleError(response);
        }
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleChangePhoneNumber = (value: string) => {
        setDatas({
            ...datas,
            phone: value,
        });
    }

    const sendEmailConfirm = async () => {
        if (!myId) return
        const response = await EmailVerficiationAPI.getMail({ owner: `/api/users/${myId}` });
        if (response.status === 200 || response.status === 201) {
            toast.success(t("email_confirmation_sent"));
        } else {
            handleError(response);
        }
        // else if (response.body.detail === "You can only request a new verification email every 5 minutes") {
        //     toast.error(t("email_confirmation_wait"));
        // } else {
        //     toast.error(t("email_confirmation_unknown"));
        // }
    }

    const sendPhoneConfirm = async () => {
        if (!myId) return
        if (datas.phone === "") {
            toast.error(t("phone_confirmation_empty"));
            return;
        }

        const datass = {
            firstname: datas.firstname,
            lastname: datas.lastname,
            ...(datas.company !== "" && { company: datas.company }),
            ...(datas.iban !== "" && { iban: datas.iban }),
            ...(datas.swiftbic !== "" && { bic: datas.swiftbic }),
            ...(datas.adress.id !== "" && { place: `/api/public/places/${datas.adress.id}` }),
            ...(datas.country !== "" && { country: `/api/public/countries/${datas.country}` }),
            ...(datas.phone !== "" && { phone: datas.phone.replace(/\s/g, "") }),
        }

        const responses = await UserAPI.putUser(datass, datas.id);

        if (responses.status === 200 || responses.status === 201) {
            const response = await PhoneVerficiationAPI.getPhone({ owner: `/api/users/${myId}` });
            if (response.status === 200 || response.status === 201) {
                toast.success(t("phone_confirmation_sent"));
                window.location.href = `/phone_confirmation?id=${response.body.id}`
            } else {
                handleError(response);
            }
        } else {
            handleError(responses);
        }
    }

    return (
        <>
            <ModalAlert open={openModal} setOpen={handleClose} handleSave={handleSubmit} />
            <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
                <AlertCloser message={t("datainfotext")} disabled={listDisabled} handleDisabled={handleChangeListDisables} />
                <p className="mb-3 text-xl font-sans font-extrabold">{t("personnaldata")}</p>

                <form className="flex flex-col" onSubmit={handleSubmit}>
                    <div className="rounded-lg">
                        <div className="flex">
                            <div className="group flex relative">
                                <button
                                    className={`${ColorPhoto(
                                        onglet
                                    )} transition-all py-1.5 px-3 cursor-pointer border rounded-l-full text-sm flex items-center justify-end`}
                                    onClick={() => setOnglet("particulier")} 
                                    type="button"
                                >
                                    Particulier
                                </button>
                            </div>
                            <div className="group flex relative">
                                <button
                                    className={`${ColorGrapher(
                                        onglet
                                    )} transition-all py-1.5 px-3 cursor-pointer border rounded-r-full text-sm flex items-center justify-start`}
                                    onClick={() => setOnglet("entreprise")}
                                    style={{ marginLeft: "-1px" }}
                                    type="button"
                                >
                                    Entreprise
                                </button>
                            </div>
                        </div>
                    </div>

                    <p className="mt-4 mb-2 text-base font-sans font-bold">{t("adress")}</p>

                    <div className="flex flex-col md:flex-col w-full gap-4">
                        <div className="flex flex-col md:flex-row w-full gap-4">
                            {onglet === "entreprise" && <InputError type="text" name="company" label={t("societyname")} className={`w-full md:w-1/2`} onChange={onChange} value={datas.company} isRequired={true} />}
                            <InputError name="firstname" type="text" label={t("firstname")} className={`w-full md:w-1/2`} onChange={onChange} value={datas.firstname} isRequired={true} />
                            <InputError name="lastname" type="text" label={t("name")}  className={`w-full md:w-1/2`} onChange={onChange} value={datas.lastname} isRequired={true} />
                        </div>
                        <div className="flex flex-col md:flex-row w-full gap-4">
                            <div className="flex flex-col md:flex-row w-full gap-4 md:w-1/3">
                                <InputCountry title={t("country")} value={datas.country} onSelect={(country) => onChange({ target: { name: "country", value: country } } as any)} disabled={false} />
                            </div>
                            <div className="flex flex-col md:flex-row w-full gap-4 md:w-2/3">
                                <InputPlaceWithValue uuid={uuid} value={datas.adress} title={t("adress")} onSelect={(id, label) => setDatas({...datas, adress: { id: id, label: label }})} disabled={false} />
                            </div>
                        </div>
                    </div>

                    <hr className="border-gray-300 mt-6" />

                    <p className="mt-4 mb-2 text-base font-sans font-bold">{t("birthContact")}</p>
                    <p className="mb-4 text-sm font-sans">{t("birthContact_d")}</p>

                    <div className="flex flex-col w-full gap-4">
                        <InputError type="date" label={t("birth")} value={datas.birth} className={`w-full md:w-1/3`} isDisabled />
                        <div className="flex flex-col md:flex-row gap-4 items-start justify-start text-xs h-fit">
                            <InputError name="email" type="email" label={t("addrMail")} className={`w-full md:w-1/3`} value={datas.email} isDisabled={true} />
                            <button type="button" className={`rounded-full px-6 py-2.5 ${general?.me?.emailVerified ? "bg-gray-400 border border-gray-400" : "bg-white hover:bg-blue-gray-50 border border-blue-gray-200"} transition-all`} disabled={general?.me?.emailVerified} onClick={() => sendEmailConfirm()}>{t("confirm")}</button>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 items-start justify-start text-xs h-fit">
                            <div className="w-full md:w-1/3">
                                <InputPhone handleChangePhoneNumber={handleChangePhoneNumber} value={datas.phone} alreadyVerified={alreadyVerified} />
                            </div>
                            <button type="button" className={`rounded-full px-6 py-2.5 ${general?.me?.phoneVerified ? "bg-gray-400 border border-gray-400" : "bg-white hover:bg-blue-gray-50 border border-blue-gray-200"} transition-all`} disabled={general?.me?.phoneVerified} onClick={() => sendPhoneConfirm()}>{t("confirm")}</button>
                        </div>
                    </div>

                    <hr className="border-gray-300 mt-6" id="coordspayementID" />

                    <p className="mt-4 mb-2 text-base font-sans font-bold">{t("coordspayement")}</p>
                    <p className="mb-4 text-sm font-sans">{t("coordspayement_d")}</p>

                    <div className="flex flex-col md:flex-row w-full gap-4">
                        <InputError type="text" label={t("iban")} value={datas.iban} name="iban" className={`w-full md:w-2/3`} onChange={onChange} />
                        <InputError type="text" label={t("swiftbic")} value={datas.swiftbic} name="swiftbic" className={`w-full md:w-1/3`} onChange={onChange} />
                    </div>
                    <button className="w-fit mx-auto mt-4">
                        <GradientButton text={t("savechanges")} />
                    </button>
                </form>
            </div>
        </>
    );
}
