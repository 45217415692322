import { faCcMastercard, faCcVisa, faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CardMe from "../../components/Box/CardMe";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButton";
import Loader from "../../components/Loader";
// import ModalSubscription from "../../components/Modals/ModalSubscription";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import Premium from "../../components/Tarifs/Premium";
import Pro from "../../components/Tarifs/Pro";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";

import PostFinance from "../../assets/images/paiement/postFinance.webp"
import PostFinanceEFinance from "../../assets/images/paiement/postFinanceEFinance.webp"
import Twint from "../../assets/images/paiement/twintLogo.png"
import { Input, Spinner, Typography } from "@material-tailwind/react";
import { Storage } from "../../services/storage";
import SubscriptionAPI from "../../services/API/Clients/SubscriptionAPI";
import { handleError } from "../../services/Errors/handleErrors";
import TableResume from "./components/TableResume";
import { toast } from "react-toastify";

export default function Subscription() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;
    const navigate = useNavigate()
    const [searchBar, setSearchBar] = React.useState(false);
    // const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [monthly, setMonthly] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingCodePromo, setIsLoadingCodePromo] = React.useState(false);
    const [codePromo, setCodePromo] = React.useState("");
    const [discount, setDiscount] = React.useState(0);
    const [codePromoIsValid, setCodePromoIsValid] = React.useState("noTested");

    const currentUrl = window.location.href.split("/")[4];
    const myId = Storage.getId();
    
    const { sub_type } = useParams();

    useEffect(() => {
        setLoading(false);
    }, []);

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    const handleChange = (e: any) => {
        setCodePromo(e.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        
        const datas = {
            plan: currentUrl === "pro" ? "PRO" : "PREMIUM",
            period: monthly ? "MONTHLY" : "YEARLY",
            owner: `/api/users/${myId}`,
            ...(codePromoIsValid && { voucherCode: codePromo }),
        };
        const response = await SubscriptionAPI.subscriptions(datas);
        if (response.status === 200 || response.status === 201) {
            if (codePromoIsValid === "valid" && discount === 100) {
                navigate("/checkout-success/subscription")
                return;
            }
            const dataBis = {
                subscription: `/api/subscriptions/${response.body.id}`,
            };
            const resp = await SubscriptionAPI.subscribeTransaction(dataBis);
            if (resp.status === 200 || resp.status === 201) {
                window.open(resp.body.redirectUrl, "_blank", "noreferrer");
            } else {
                handleError(response);
            }
            setIsLoading(false);
        } else {
            handleError(response);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const handleCheckCodePromo = async () => {
        setIsLoadingCodePromo(true);
        if (codePromo === "") {
            toast.warn(t("codePromoEmpty"));
            setIsLoadingCodePromo(false);
            return;
        }
        setCodePromoIsValid("noTested");
        const response = await SubscriptionAPI.getVoucherCode(codePromo, monthly ? "MONTHLY" : "YEARLY", currentUrl === "pro" ? "PRO" : "PREMIUM");
        if (response.status === 200) {
            toast.success(t("codePromoValid"));
            setCodePromoIsValid("valid");
            setDiscount(response.body.discount);
        } else {
            handleError(response);
            setCodePromoIsValid("invalid");
        }
        setIsLoadingCodePromo(false);
    };

    return (
        <>
            <Loader loading={loading} />
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4  pb-[340px] md:pb-36 min-h-screen">
                    <motion.div
                        variants={{
                            visible: { top: "48px", paddingTop: "24px" },
                            hidden: { top: "0px", paddingTop: "6px" },
                            barsInactive: { top: "48px", paddingTop: "22px" },
                            barsActive: { top: "102px", paddingTop: "18px" },
                        }}
                        animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                        transition={{
                            duration: 0.35,
                            ease: "easeInOut",
                        }}
                        className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200"
                    >
                        <TitlePage mt={0} mb={0}>
                            {sub_type === "premium" ? t("obtenirPremium") : t("obtenirPro")}
                        </TitlePage>
                    </motion.div>
                    <WrapperGrayZone pt="0" px="8">
                        <div className="mx-auto py-6 ">
                            <div className="flex flex-col md:flex-row px-2 md:px-8 w-full h-full max-w-7xl mx-auto gap-4">
                                <div className="pt-4 md:pt-0 w-full md:w-1/2 min-h-full flex flex-col items-center">
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="mx-auto w-fit flex justify-center items-center mb-10 mt-2">
                                            <button
                                                onClick={() => setMonthly(true)}
                                                className={`flex px-8 py-2 justify-center items-center cursor-pointer border transition-all rounded-l-full w-44 ${
                                                    monthly ? "border-r-transparent bg-gray-100" : ""
                                                }`}
                                            >
                                                <span className="text-base">{t("monthly")}</span>
                                            </button>
                                            <button
                                                onClick={() => setMonthly(false)}
                                                className={`flex px-8 py-2 justify-center items-center cursor-pointer border transition-all rounded-r-full w-44 ${
                                                    !monthly ? "border-l-transparent bg-orange-500 text-white" : ""
                                                }`}
                                            >
                                                <span className="text-base">{t("yearlyProm")}</span>
                                            </button>
                                        </div>
                                    </div>
                                    {sub_type === "premium" ? <Premium monthly={monthly} isMiddle={false} /> : <Pro monthly={monthly} isMiddle={false} />}
                                </div>
                                <div className="w-full md:w-1/2 bg-gray-50 min-h-full flex justify-center">
                                    <div className="flex flex-col gap-4 justify-center items-center w-full">
                                        <div className="flex flex-col gap-4 rounded-3xl bg-gray-100 w-full px-6 py-4">
                                            <h1 className="text-lg font-bold">{t("summary")}</h1>
                                            <TableResume
                                                currentUrl={currentUrl}
                                                monthly={monthly}
                                                codePromoIsValid={codePromoIsValid}
                                                discount={discount}
                                            />
                                        </div>

                                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 rounded-3xl bg-gray-100 w-full px-6 py-4 md:py-2 md:justify-between md:items-center">
                                            <Typography 
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {t("codePromoUse")}
                                            </Typography>
                                            <div className="flex gap-4 w-fit items-center">
                                                <div className="relative w-fit">
                                                    <Input
                                                        type="text"
                                                        color="orange"
                                                        label={t("promCode")}
                                                        name="codePromo"
                                                        onChange={handleChange}
                                                        value={codePromo}
                                                        className="bg-white"
                                                    />
                                                    <div className="absolute top-1.5 right-1.5 p-1 bg-gray-100 rounded-lg flex justify-center items-center">
                                                        <Typography 
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal cursor-pointer"
                                                            onClick={handleCheckCodePromo}
                                                        >
                                                            {isLoadingCodePromo ? <Spinner className="w-4" /> : t("OK")}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                {codePromoIsValid === "valid"
                                                    ? <Typography 
                                                        variant="small"
                                                        color="green"
                                                        className="font-normal"
                                                    >
                                                        <FontAwesomeIcon icon="check" />
                                                    </Typography>
                                                    :  codePromoIsValid === "invalid" && <Typography 
                                                        variant="small"
                                                        color="red"
                                                        className="font-normal"
                                                    >
                                                        <FontAwesomeIcon icon="x" />
                                                    </Typography>
                                                }
                                            </div>
                                        </div>

                                        <CardMe />

                                        <p className="text-xs text-center my-4" dangerouslySetInnerHTML={{ __html: t("toProceed")}}></p>
                                            
                                        <button className="w-fit mx-auto" onClick={handleSubmit}>
                                            <GradientButton text={t("procedPaiement")} isLoading={isLoading} />
                                        </button>
                                        <div className="flex flex-row gap-4 mx-auto text-3xl text-gray-400">
                                            <FontAwesomeIcon icon={faCcVisa} className="text-blue-900" />
                                            <FontAwesomeIcon icon={faCcMastercard} className="text-red-600" />
                                            <img src={PostFinance} className="h-7 rounded" />
                                            <img src={PostFinanceEFinance} className="h-7 rounded" />
                                            <img src={Twint} className="h-7 rounded" />
                                            <FontAwesomeIcon icon={faCcPaypal} className="text-blue-900" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
