import moment from "moment";

export interface PelliclePicture {
    id: string,
    minPath: string,
    uploadedAt: Date,
    canBeSold: boolean
    deleting: boolean
    placeholder: boolean
    place?: {
        id?: string
        label?: string
    }
}

export const DEFAULT_PICTURE: PelliclePicture = {
    id: "",
    minPath: "",
    uploadedAt: new Date(),
    canBeSold: false,
    deleting: false,
    placeholder: false,
}

export interface UploadedPicturesChunk {
    date: string;
    pictures: PelliclePicture[];
}

export const picturesToChunks = (pictures: PelliclePicture[]): UploadedPicturesChunk[] => {
    const chunks: UploadedPicturesChunk[] = [];
    pictures.forEach((picture) => {
        const date = moment(picture.uploadedAt).format("DD.MM.YYYY");
        const chunk = chunks.find((c) => c.date === date);
        const pic = {
            ...DEFAULT_PICTURE,
            ...picture,
        }
        if (chunk) {
            chunk.pictures.push(pic);
        } else {
            chunks.push({
                date,
                pictures: [pic],
            });
        }
    });
    return chunks;
}

export const addPictureToChunks = (chunks: UploadedPicturesChunk[], picture: PelliclePicture): UploadedPicturesChunk[] => {
    const date = moment(picture.uploadedAt).format("DD.MM.YYYY");
    const chunk = chunks.find((c) => c.date === date);
    const pic = {
        ...picture,
        deleting: false,
    }
    if (chunk) {
        chunk.pictures.push(pic);
    } else {
        chunks.push({
            date,
            pictures: [pic],
        });
    }
    return chunks;
}