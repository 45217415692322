import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader";
import TitlePage from "../../components/TitlePage";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import Pictures from "./components/Pictures";
import Portfolio from "./components/Portfolio";
import Sells from "./components/Sells";


export default function Stats() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("statistics")}`;
    const navigate = useNavigate();
    const { type } = useParams();
    const [searchBar, setSearchBar] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [actualUrl, setActualUrl] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const handleLinkClick = (url:string) => {
        navigate(url);
    };

    useEffect(() => {
        if (type === "portfolio") setActualUrl("portfolio");
        else if (type === "ventes") setActualUrl("ventes")
        else setActualUrl("photos");
        setLoading(false);
    }, [type]);
    
    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
                    <TitlePage>
                        {t("statistics")}
                    </TitlePage>
                    <div className="flex flex-col w-full pb-44 h-full">
                        <motion.div 
                            variants={{
                                visible: { top: "48px", paddingTop: "24px" },
                                hidden: { top: "0px", paddingTop: "6px" },
                                barsInactive: { top: "48px", paddingTop: "22px" },
                                barsActive: { top: "102px", paddingTop: "18px" },
                            }}
                            animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible" }
                            transition={{ 
                                duration: 0.35,
                                ease: "easeInOut"
                            }}
                            className="border-b border-gray-200 w-full sticky md:static top-0 bg-white z-30"
                        >
                            <ul className="flex flex-row flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 justify-center w-full">
                                <li className="mr-2">
                                    <button
                                        onClick={() => handleLinkClick("/statistics")}
                                        className={`${
                                            actualUrl === "photos"
                                                ? "border-orange-400 text-orange-400"
                                                : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                        } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                    >
                                        <FontAwesomeIcon icon="image" />
                                        <span className="hidden md:flex">{t("photos")}</span>
                                    </button>
                                </li>
                                <li className="mr-2">
                                    <button
                                        onClick={() => handleLinkClick("/statistics/portfolio")}
                                        className={`${
                                            actualUrl === "portfolio"
                                                ? "border-orange-400 text-orange-400"
                                                : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                        } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                    >
                                        <FontAwesomeIcon icon="images" />
                                        <span className="hidden md:flex">{t("portfolio")}</span>
                                    </button>
                                </li>
                                <li className="mr-2">
                                    <button
                                        onClick={() => handleLinkClick("/statistics/ventes")}
                                        className={`${
                                            actualUrl === "ventes"
                                                ? "border-orange-400 text-orange-400"
                                                : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                        } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                    >
                                        <FontAwesomeIcon icon="money-bill" />
                                        <span className="hidden md:flex">{t("stats_sales_label")}</span>
                                    </button>
                                </li>
                            </ul>
                        </motion.div>
                        {actualUrl === "photos" && <Pictures />}
                        {actualUrl === "portfolio" && <Portfolio />}
                        {actualUrl === "ventes" && <Sells />}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
