import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ModalOrganize from "../../../components/Modals/Organize/ModalOrganize";
import SearchResult from "../../../components/SearchResult/SearchResult";
import { RootState } from "../../../services/redux/store";
import { useOutletContext } from "react-router-dom";

export default function PhotosEdit() {
  const [handleReloadPictures] = useOutletContext<[handleReloadPictures: () => void]>();
  const portfolio = useSelector((state: RootState) => state.form);
  const [openModal, setOpenModal] = React.useState(false);

  const { t } = useTranslation();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    handleReloadPictures();
    setOpenModal(false);
  };

  return (
    <>
      <ModalOrganize open={openModal} onClose={handleCloseModal} />
      <div className="mx-auto px-2 md:px-8 py-2 md:py-0">
        <SearchResult handleOpenOrganizePictureModal={portfolio.pictureCount > 1 ? handleOpenModal : undefined} />
      </div>
    </>
  );
}
