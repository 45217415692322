import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import {
  PictureDrawerForFilters,
  PictureResult,
} from "../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../components/SearchResult/mobile/MobileFilterButton";
import TitlePageNoMaxSize from "../../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../../components/WrapperGrayZone";
import SearchResult from "../../../components/SearchResult/SearchResult";

export default function Popular() {
  const { t } = useTranslation();
  document.title = `ezoom | ${t("photos")}`;
  const [searchBar, setSearchBar] = useState(true);
  const [hidden, setHidden] = useState(false);

  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, "change", (latest: number) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <PictureDrawerForFilters />
      <HeaderForAnimation />
      <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
      <PictureResult
        overrides={{
          pagetype: "popular",
          popular: true,
        }}
        hideFilters={["popular", "recent"]}
        defaultSort="popular"
        galleries={false}
      >
        <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
          <motion.div
            variants={{
              visible: { top: "48px", paddingTop: "24px" },
              hidden: { top: "0px", paddingTop: "6px" },
              barsInactive: { top: "48px", paddingTop: "22px" },
              barsActive: { top: "102px", paddingTop: "18px" },
            }}
            animate={
              hidden
                ? searchBar
                  ? "barsInactive"
                  : "hidden"
                : searchBar
                ? "barsActive"
                : "visible"
            }
            transition={{
              duration: 0.35,
              ease: "easeInOut",
            }}
            className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200"
          >
            <TitlePageNoMaxSize mt={0} mb={0}>
              <FontAwesomeIcon icon="images" className="text-orange-500" />{" "}
              {t("populars")}
            </TitlePageNoMaxSize>
            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
              <MobileFilterButton />
            </div>
          </motion.div>
          <WrapperGrayZone pt="0" pb="32" px="4">
            <SearchResult />
          </WrapperGrayZone>
        </div>
      </PictureResult>
      <Footer />
    </div>
  );
}
