import React from 'react';

type StarRatingProps = {
  rating: number; // Note de 0 à 5 (ou autre échelle si nécessaire)
  maxStars?: number; // Nombre total d'étoiles à afficher (par défaut 5)
};

const StarRating: React.FC<StarRatingProps> = ({ rating, maxStars = 5 }) => {
  // Fonction pour déterminer le type d'étoile (pleine, partielle ou vide)
  const getStarType = (index: number) => {
    const integerPart = Math.floor(rating);
    const decimalPart = rating - integerPart;

    if (index < integerPart) {
      return 'full'; // Étoile pleine
    } else if (index === integerPart) {
      if (decimalPart >= 0.25 && decimalPart <= 0.75) {
        return 'half'; // Étoile partiellement remplie
      } else if (decimalPart >= 0.75) {
        return 'full'; // Étoile pleine
      } else {
        return 'empty'; // Étoile vide
      }
    } else {
      return 'empty'; // Étoile vide
    }
  };

  // Rendu du composant
  return (
    <div className="flex">
      {Array.from({ length: maxStars }, (_, index) => {
        const starType = getStarType(index);
        return (
          <svg
            key={index}
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              starType === 'full' ? 'text-yellow-500 w-5 h-auto fill-current' : starType === 'half' ? 'text-yellow-500 w-5 h-auto fill-current' : 'text-gray-500 w-6 h-6'
            }`}
            viewBox={starType === 'full' || starType === 'half' ? "0 0 16 16" : "0 0 24 24"}
            fill='none'
            stroke='currentColor'
            strokeWidth={starType === 'full' || starType === 'half' ? "0" : "1.5"}
          >
            <path
              d={
                starType === 'full'
                  ? 'M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'
                  : starType === 'half'
                  ? 'M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z'
                  : "M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              }
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        );
      })}
    </div>
  );
};

export default StarRating;
