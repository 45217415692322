import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Option, Radio, Select, Textarea, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import SwipeGaleryUnique from "../../../../components/Box/Gallery/SwipeGaleryUnique";
import PictureWithoutInfinite from "../../../../components/Box/Photo/PictureWithoutInfinite";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { GalleryInterfacePerformance } from "../../../../components/Interfaces/GalleryInterface";
import JsonIconDico from "../../../../components/Interfaces/IconDico.json";
import { PictureSearch } from "../../../../components/Interfaces/PictureType";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import ServiceAPI from "../../../../services/API/Clients/ServiceAPI";
import ServiceTypeAPI from "../../../../services/API/Clients/ServiceTypeAPI";
import { updateServices } from "../../../../services/redux/actions/formAction";
import ModalChangeBannerPerf from "./ModalChangeBackgroundPortfolio";
import ModalSelectGallery from "./ModalSelectGallery";
import ModalSelectPictures from "./ModalSelectPicture";
import { handleError } from "../../../../services/Errors/handleErrors";
import { PerformanceProps, updateBanner, updateBannerOffset, updateDescription, updateGalleries, updateKeywords, updatePictures, updatePriceCeil, updatePriceType, updatePriceValue, updateResetPerformance, updateSelectorPrices, updateTitle, updateType } from "../../../../services/redux/actions/performanceActions";
import { PortfolioState } from "../../../../components/Interfaces/PortfolioType";
import { title } from "process";
import { updatePerformancesPE } from "../../../../services/redux/actions/performanceEditAction";
import KeywordsAPI from "../../../../services/API/Clients/KeywordsAPI";

export default function PerformanceAdd() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portfolio = useSelector((state: any) => state.form);
    const performance = useSelector((state: any) => state.performance);
    const general = useSelector((state: any) => state.general);
    const [isHover, setIsHover] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [openModalBanner, setOpenModalBanner] = React.useState(false);
    const [openModalPictures, setOpenModalPictures] = React.useState(false);
    const [openModalGalleries, setOpenModalGalleries] = React.useState(false);
    const [showFullList, setShowFullList] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState<{
        enumValue: string,
        frLabel: string,
        enLabel: string
    }[]>([]);
    const [servicesTypes, setServicesTypes] = React.useState<
        {
            enumValue: string;
            frLabel: string;
            enLabel: string;
        }[]
    >([]);

    useEffect(() => {
        if (general === undefined || general.me === undefined || general.me.plan !== "PRO") {
            navigate("/portfolio/edit/photos")
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ServiceTypeAPI.services();
            if (response.status === 200 || response.status === 201) setServicesTypes(response.body);
            else handleError(response);
        };
        fetchData();
    }, []);

    const handleSetBanner = (e: { id: string; minPath: string }) => { dispatch(updateBanner(e)); };

    const handleBannerOffset = (e: number) => { dispatch(updateBannerOffset(e)); };

    const handleInputDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => { dispatch(updateDescription(e.target.value)); };
    const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => { dispatch(updateTitle(e.target.value)); };

    const handleSelectSelectorPrices = (e: string) => { dispatch(updateSelectorPrices(e)); };

    const handleSelectPriceType = (e: string) => { dispatch(updatePriceType(e)); };
    const handleInputPriceValue = (e: number) => { dispatch(updatePriceValue(e)); };
    const handleInputPriceCeil = (e: number) => { dispatch(updatePriceCeil(e)); };

    const handleRadioPriceFixed = () => { dispatch(updatePriceType("fixed")); };
    const handleRadioPriceOnDemand = () => { dispatch(updatePriceType("ondemand")); };
    const handleRadioPriceFree = () => { dispatch(updatePriceType("free")); };

    const handleSelectType = async (e: string) => { 
        dispatch(updateType(e)) 
        const response = await KeywordsAPI.keywordsPerformance(e);
        if (response.status === 200 || response.status === 201) {
            setSuggestions(response.body);
            filteringKeywords(response.body);
        } else {
            handleError(response);
        }
    };

    const handleSelectPicture = (e: PictureSearch[]) => {
        const datas = e.map((picture: PictureSearch) => {
            return {
                id: picture.id,
                minPath: picture.minPath
            }
        });
        dispatch(updatePictures(datas));
    }

    const handleSelectGallery = (e: GalleryInterfacePerformance[]) => {
        const datas = e.map((gallery: GalleryInterfacePerformance, index: number) => {
            return {
                gallery: gallery.id ?? "",
                index: index ?? 0,
                banner: {
                    minPath: gallery?.banner?.minPath ?? DefaultBannerEzoom,
                },
                bannerOffset: gallery.bannerOffset ?? 0,
                title: gallery.title ?? "",
                id: gallery.id ?? ""
            }
        })
        dispatch(updateGalleries(datas))
    }

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const textMajor = event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1);
            const myData = {
                value: textMajor,
                label: textMajor,
                type: "fromCustom",
            };
            const newList = [...performance.keywords, myData]
            dispatch(updateKeywords(newList));
            event.currentTarget.value = "";
        }
    };

    const removeTag = (tag: number) => {
        const filtered = performance.keywords.filter((item: any, index: number) => index !== tag);
        dispatch(updateKeywords(filtered));
    };

    const addTagToList = (value: string, label: string) => {
        const myData = {
            value: value,
            label: label,
            type: "fromList",
        };
        const newList = [...performance.keywords, myData];
        dispatch(updateKeywords(newList));
    }

    useEffect(() => {
        filteringKeywords();
    }, [performance.keywords]);

    const priceDefine = (
        <div className={`flex ${performance.priceType === "range" ? "flex-col" : "flex-row"} items-center w-full gap-2`}>
            <Select
                label=""
                variant="standard"
                color="orange"
                value={performance.selectorPrices}
                onChange={(e) => {
                    e && handleSelectSelectorPrices(e);
                    e && handleSelectPriceType(e);
                }}
                containerProps={{ className: "min-w-[72px]" }}
            >
                <Option value="fixed">{t("fixprice")}</Option>
                <Option value="from">{t("from")}</Option>
                <Option value="range">{t("interval")}</Option>
            </Select>
            <div className="flex flex-row items-center gap-2">
                <Input
                    type="number"
                    color="orange"
                    label={t("chf")}
                    value={performance.priceValue}
                    onChange={(e) => handleInputPriceValue(Number(e.target.value))}
                    containerProps={{ className: "min-w-[72px]" }}
                />
                {performance.priceType === "range" && (
                    <Input
                        type="number"
                        color="orange"
                        label={t("chf")}
                        value={performance.priceCeil}
                        onChange={(e) => handleInputPriceCeil(Number(e.target.value))}
                        containerProps={{ className: "min-w-[72px]" }}
                    />
                )}
            </div>
        </div>
    );

    const handleOpenModalBanner = () => {
        setOpenModalBanner(!openModalBanner);
    };

    const handleOpenModalPicture = () => {
        setOpenModalPictures(!openModalPictures);
    };

    const handleOpenModalGallery = () => {
        setOpenModalGalleries(!openModalGalleries);
    };

    const handleSubmit = async (arg: boolean) => {
        if (loading) return;
        setLoading(true);
        
        if (!performance?.banner || !performance.banner?.id) {
            toast.warning(t("nobanner"));
            setLoading(false);
            return;
        } else if (!performance.type) {
            toast.warning(t("notype"));
            setLoading(false);
            return;
        } else if (!performance.title) {
            toast.warning(t("notitle"));
            setLoading(false);
            return;
        } else if (!portfolio || !portfolio.id) {
            toast.warning(t("noidPortfolio"));
            setLoading(false);
            return;
        } else if ((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue === 0) {
            toast.warning(t("noprice"));
            setLoading(false);
            return;
        } else if (performance.priceType === "range" && performance.priceCeil === 0) {
            toast.warning(t("noprice"));
            setLoading(false);
            return;
        }

        const datas = {
            banner: `/api/public/pictures/${performance?.banner?.id}`,
            title: performance.title,
            portfolio: `/api/public/portfolios/${portfolio.id}`,
            bannerOffset: performance.bannerOffset,
            ...(performance.description !== "" && {description: performance.description}),
            ...(performance.priceType !== "" && {priceType: performance.priceType}),
            ...((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue !== 0 && {priceValue: performance.priceValue}),
            ...(performance.priceType === "range" && performance.priceCeil !== 0 && {priceCeil: performance.priceCeil}),
            ...(performance.type !== "" && {type: `/api/service-types/${performance.type}`}),
            ...(performance.pictures.length > 0 && {pictures: performance.pictures.map((picture: { minPath: string, id: string }, index:number) => {return {
                picture: `/api/public/pictures/${picture.id}`,
                index: index
            }})}),
            ...(performance.galleries.length > 0 && {galleries: performance.galleries.map((gallery: GalleryInterfacePerformance, index:number) => {return {
                gallery: `/api/public/galleries/${gallery.id}`,
                index: index
            }})}),
            ...(performance.keywords.length > 0 && {keywords: performance.keywords.map((tag: string, index:number) => {return {
                customLabel: tag,
                index: index
            }})}),
            ...(performance.keywords.length > 0 && {
                keywords:
                    performance.keywords.map((tag: { 
                        value: string; 
                        label: string; 
                        type: string
                     }, index: number) => (tag.type === "fromCustom" ? { index: index, customLabel: tag.label } : { index: index, keyword: `/api/keywords/${tag.value}` })),
            }),
        }

        const response = await ServiceAPI.create(datas);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successService"));
            if (arg) {
                const responsePublish = await ServiceAPI.publish(response.body.id);
                if (responsePublish.status === 200 || responsePublish.status === 201) {
                    toast.success(t("published"));
                    const getAllServcies = await PortfolioAPI.getServicesDraft(portfolio.id);
                    if (getAllServcies.status === 200 || getAllServcies.status === 201) {
                        dispatch(updateServices(getAllServcies.body));
                        dispatch(updatePerformancesPE(getAllServcies.body));
                        dispatch(updateResetPerformance())
                        navigate(`/portfolio/edit/performance/${response.body.id}`);
                    } else {
                        handleError(getAllServcies);
                    }
                } else {
                    handleError(responsePublish);
                }
            } else {
                const getAllServcies = await PortfolioAPI.getServicesDraft(portfolio.id);
                if (getAllServcies.status === 200 || getAllServcies.status === 201) {
                    dispatch(updateServices(getAllServcies.body));
                    dispatch(updatePerformancesPE(getAllServcies.body));
                    dispatch(updateResetPerformance())
                    navigate(`/portfolio/edit/performance/${response.body.id}`);
                } else {
                    handleError(getAllServcies);
                }
            }
        } else {
            handleError(response);
            setLoading(false);
        }
    }

    const filteringKeywords = (suggestionHardCode?: []) => {
        let data
        if (suggestionHardCode !== undefined) {
            data = suggestionHardCode
        } else 
            data = suggestions
        if (performance.keywords.length > 0) {
            const filteredSecondList = data.filter((key) => {
                const labelMatches = performance.keywords.some(
                    (item: { label: string, value: string }) =>
                        item.label === key.frLabel ||
                        item.label === key.enLabel ||
                        item.value === key.enumValue
                );
                return !labelMatches;
            });
            setSuggestions(filteredSecondList);
    
        } else {
            setSuggestions(data);
        }
    }

    return (
        <>
            <ModalChangeBannerPerf open={openModalBanner} setOpen={handleOpenModalBanner} setBackground={handleSetBanner} />
            <ModalSelectPictures open={openModalPictures} setOpen={handleOpenModalPicture} pictures={performance.pictures} setPictures={handleSelectPicture} />
            <ModalSelectGallery open={openModalGalleries} setOpen={handleOpenModalGallery} galleries={performance.galleries} setGalleries={handleSelectGallery} />
            <div className="mx-auto px-2 md:px-4 py-6 flex flex-col md:flex-row flex-wrap w-full max-w-6xl pb-[20px] md:pb-6">
                <div className="w-full flex flex-row items-center justify-between md:px-4">
                    <div 
                        className="flex flex-row items-center cursor-pointer" 
                        onMouseEnter={() => setIsHover(true)} 
                        onMouseLeave={() => setIsHover(false)}
                        onClick={() => {
                            navigate("/portfolio/edit/performances")
                        }} 
                    >
                        <div
                            className={`w-6 text-sm h-6 mr-2 flex items-center justify-center border transition-all ${isHover ? " hover:border-purple-50 hover:text-purple-50" : "border-purple-500 text-purple-500"} rounded-lg`}
                        >
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                        <Typography className={`hidden md:flex text-lg font-bold text-center ${isHover ? "text-gray-500" : "text-black"} transition-all`}>{t("backtoperf")}</Typography>
                    </div>
                    <div className="flex flex-row">
                        {portfolio.published && (
                            <>
                                <button onClick={() => handleSubmit(true)} className="hidden md:flex">
                                    <GradientButton text={t("saveandpublish")} />
                                </button>
                                <button onClick={() => handleSubmit(true)} className="md:hidden">
                                    <IconButton className="rounded-full hover:bg-orange-200 transition-all duration-400" color="orange" size="sm">
                                        <FontAwesomeIcon icon="check" />
                                    </IconButton>
                                </button>
                            </>
                        )}
                        <button onClick={() => handleSubmit(false)} className="hidden md:flex mx-4">
                            <button className="border inline w-fit hover:text-black hover:bg-gray-50 transition-all bg-white py-2 mt-0.5 px-4 rounded-full">{t("save")}</button>
                        </button>
                        <button onClick={() => handleSubmit(false)} className="md:hidden mx-2">
                            <IconButton className="rounded-full hover:bg-orange-200 transition-all duration-400" color="orange" size="sm">
                                <FontAwesomeIcon icon="save" />
                            </IconButton>
                        </button>
                    </div>
                </div>
                <div 
                    className="w-full md:mx-2 h-72 mt-8 rounded bg-cover" 
                    style={{
                        backgroundPositionY: `${100 - performance.bannerOffset}%`,
                        backgroundImage: `url(${performance?.banner?.minPath ?? DefaultBannerEzoom})`
                    }}
                >
                    {performance.banner.id ? (
                        <div className="w-full h-full relative">
                            <div style={{ height: "220px", width: "50px" }} className="absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center">
                                <input
                                    type="range"
                                    min={0}
                                    max={100}
                                    value={performance.bannerOffset}
                                    onChange={(event) => handleBannerOffset(Number(event.target.value))}
                                    className="-rotate-90 transparent h-[4px] w-[220px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                                    id="customRange1"
                                />
                            </div>
                            <button 
                                color="white" 
                                className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] text-xs bg-white text-blue-gray-900 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer absolute right-3 bottom-3" 
                                onClick={() => setOpenModalBanner(!openModalBanner)}
                            >
                                <FontAwesomeIcon icon="pen" />
                            </button>
                            {/* <img src={background.minPath} alt="performance" className="w-full h-full object-cover rounded" /> */}
                        </div>
                    ) : (
                        <button
                            className="w-full h-full flex flex-col items-end justify-end cursor-pointer border rounded relative"
                            onClick={() => setOpenModalBanner(!openModalBanner)}
                        >
                            <IconButton 
                                color="white" 
                                className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer absolute right-3 bottom-3" 
                                onClick={() => setOpenModalBanner(!openModalBanner)}
                            >
                                <FontAwesomeIcon icon="pen" />
                            </IconButton>
                        </button>
                    )}
                </div>
                <div className="w-full flex flex-col md:flex-row mt-4 md:mx-2 gap-4">
                    <div className="w-full md:w-2/3 flex flex-col gap-4">
                        <div className="w-full flex flex-col gap-4 bg-white p-2 rounded">
                            <Input type="text" color="orange" label={t("title")} className="bg-white" name="title" value={performance.title} onChange={handleInputTitle} />
                            <Textarea
                                color="orange"
                                label={t("captionperf")}
                                className="bg-white"
                                name="description"
                                value={performance.description}
                                onChange={handleInputDescription}
                            />
                        </div>
                        <div className="bg-white p-2 rounded hidden md:block">
                            <div className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-3">
                                <Typography className="text-base ml-2 font-bold text-left">{t("photos")}</Typography>
                                <button
                                    className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                    onClick={() => setOpenModalPictures(!openModalPictures)}
                                >
                                    {t("addpicture")}
                                </button>
                            </div>
                            <PictureWithoutInfinite pictures={performance.pictures} heart={true} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="w-full flex-col rounded bg-white p-2">
                            <Typography className="text-base ml-2 font-bold text-left">{t("price")}</Typography>
                            <Radio
                                label={priceDefine}
                                color="orange"
                                name="priceMobile"
                                value="define"
                                checked={performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range"}
                                onChange={(e) => handleRadioPriceFixed()}
                            />
                            <Radio
                                label={t("ondemand")}
                                color="orange"
                                name="priceMobile"
                                value="ondemand"
                                checked={performance.priceType === "ondemand"}
                                onChange={(e) => handleRadioPriceOnDemand()}
                            />
                            <Radio
                                label={t("free")}
                                color="orange"
                                name="priceMobile"
                                value="free"
                                checked={performance.priceType === "free"}
                                onChange={(e) => handleRadioPriceFree()}
                            />
                        </div>
                        <div className="w-full flex-col rounded bg-white p-2 mt-4 flex">
                            <Typography className="text-base ml-2 font-bold text-left">{t("keywords")}</Typography>
                            <Typography className="text-sm ml-2 text-justify">{t("choosecategory")}</Typography>

                            <div className="mt-2 w-full">
                                <Select
                                    label={t("performance")}
                                    variant="standard"
                                    color="orange"
                                    name="selectWeb"
                                    onChange={(e) => e && handleSelectType(e)}
                                >
                                    {servicesTypes && servicesTypes.length > 0 ? (
                                        servicesTypes?.map((service: any) => {
                                            const iconValue = JsonIconDico[service.enumValue as keyof typeof JsonIconDico] as IconProp;
                                            return (
                                                <Option value={service.enumValue} key={service.enumValue}>
                                                    <FontAwesomeIcon icon={iconValue} className="mr-2" />
                                                    {service.frLabel}
                                                </Option>
                                            );
                                        })
                                    ) : (
                                        <Option value="none">{t("nooption")}</Option>
                                    )}
                                </Select>
                            </div>
                            <div className="mt-4">
                                <Input
                                    className="bg-white"
                                    label={t("tags")}
                                    color="orange"
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => addTag(event)}
                                />
                                <div className="flex flex-row flex-wrap gap-2 mt-2">
                                    {performance.keywords.map((tag: { value: string; label: string; type: string }, index: number) => (
                                        <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.value}>
                                            <p>{tag.label}</p>
                                            <FontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer mt-1" onClick={() => removeTag(index)} />
                                        </div>
                                    ))}
                                </div>
                                {suggestions.length > 0 && (
                                    <div
                                        className={`flex flex-row relative justify-between overflow-hidden ${
                                            showFullList ? "h-auto pb-7 items-end" : "h-10 items-start"
                                        } transition-all duration-700 ease-in-out`}
                                    >
                                        <div className="flex flex-row flex-wrap gap-2 mt-2">
                                            {suggestions.map((tag) => (
                                                <button key={tag?.enumValue ?? ""} className="hover:opacity-90" onClick={() => addTagToList(tag?.enumValue ?? "", tag?.frLabel ?? "")}>
                                                    <div className="bg-blue-gray-200/30 text-blue-gray-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag?.enumValue ?? ""}>
                                                        <p>{tag?.frLabel ?? ""}</p>
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                        <button
                                            className="h-10 mt-0.5"
                                            onClick={() => setShowFullList(!showFullList)}
                                        >
                                            <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between">
                                                {showFullList ? <FontAwesomeIcon icon="chevron-up" /> : "..."}
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                            {/* <div className={`peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border-t-transparent border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] ${isFocused ? "border-orange-500 border-2" : "border-blue-gray-200"} bg-white relative`}>
                                <input onKeyDown={(event: any) => addTag(event)} onFocus={() => setIsFocused(!isFocused)} onBlur={() => setIsFocused(!isFocused)} type="text" className={`w-full text-blue-gray-700 relative z-20 bg-transparent focus:outline-none focus:border-purple-500 focus:ring-0`} value={tagInput.toLowerCase()} onChange={handleNewRecommandation} />
                                <p className="absolute top-2.5 left-3 text-blue-gray-200 z-1">{recommandation.label.toLowerCase()} {recommandation.label !== "" && <span className="border p-1 rounded">Tabulation</span>}</p>

                                <label className={`flex w-full h-full select-none pointer-events-none absolute left-0 font-normal truncate ${isFocused ? "leading-tight text-orange-500 before:border-l-2 after:border-r-2 before:border-t-2 after:border-t-2 before:border-orange-500 after:border-orange-500 -mt-[2px]" : "leading-tight text-blue-gray-400"} transition-all -top-2 text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:border-t before:border-l before:pointer-events-none before:transition-all after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:border-t after:border-r after:pointer-events-none after:transition-all before:border-blue-gray-200 after:border-blue-gray-200`}>
                                    {t("tags")}{" "}
                                </label>
                            </div>
                            <div className="flex flex-row flex-wrap gap-2 mt-2">
                                {listTags.map((tag: any, index: number) => (
                                    <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.label}>
                                        <p>{tag.label}</p>
                                        <FontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer mt-1" onClick={() => removeTag(tag.label)} />
                                    </div>
                                ))}
                            </div>
                            {listKeywords.length > 0 && (
                                <div
                                    className={`flex flex-row relative justify-between overflow-hidden ${
                                        showFullList ? "h-auto pb-7 items-end" : "h-10 items-start"
                                    } transition-all duration-700 ease-in-out`}
                                >
                                    <div className="flex flex-row flex-wrap gap-2 mt-2">
                                        {listKeywords.map((tag: any) => (
                                            <button key={tag.value} className="hover:opacity-90" onClick={() => addTag(tag.value)}>
                                                <div className="bg-blue-gray-200/30 text-blue-gray-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.label}>
                                                    <p>{tag.label}</p>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                    <button
                                        className="h-10 mt-0.5"
                                        onClick={() => setShowFullList(!showFullList)}
                                    >
                                        <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between">
                                            {showFullList ? <FontAwesomeIcon icon="chevron-up" /> : "..."}
                                        </div>
                                    </button>
                                </div>
                            )} */}
                        </div>
                        <div className="w-full flex-col rounded bg-white p-2 mt-4 flex">
                            <div className="flex flex-col md:flex-row flex-wrap justify-between items-center">
                                <div className="flex flex-row items-center justify-between w-full mb-3">
                                    <Typography className="text-base ml-2 font-bold text-left">{t("galeries")}</Typography>
                                    <button
                                        className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                        onClick={() => setOpenModalGalleries(!openModalGalleries)}
                                    >
                                        {t("addgal")}
                                    </button>
                                </div>
                                {performance.galleries.length > 0 ? (
                                    <SwipeGaleryUnique
                                        galeries={performance.galleries.map((gallerie: GalleryInterfacePerformance) => {
                                            return {
                                                id: gallerie.id,
                                                title: gallerie.title,
                                                banner: {
                                                    minPath: gallerie?.banner?.minPath ?? DefaultBannerEzoom,
                                                },
                                                bannerOffset: gallerie.bannerOffset,
                                            };
                                        })}
                                    />
                                ) : (
                                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                        <FontAwesomeIcon icon="images" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                                        <Typography className="text-base text-gray-700 mb-12">{t("noGallerie")}</Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="md:hidden bg-white p-2 rounded mt-4">
                            <div className="flex flex-row flex-wrap justify-between items-center mb-3">
                                <Typography className="text-base ml-2 font-bold text-left">{t("photos")}</Typography>
                                <button
                                    className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                    onClick={() => setOpenModalPictures(!openModalPictures)}
                                >
                                    {t("addpicture")}
                                </button>
                            </div>
                            <PictureWithoutInfinite pictures={performance.pictures} heart={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



export const saveNewPerformance = async (performance: PerformanceProps, portfolio: PortfolioState, t: any, dispatch: any) => {
    if (!performance?.banner || !performance.banner?.id) {
        toast.warning(t("nobanner"));
        return;
    } else if (!performance.type) {
        toast.warning(t("notype"));
        return;
    } else if (!performance.title) {
        toast.warning(t("notitle"));
        return;
    } else if (!portfolio || !portfolio.id) {
        toast.warning(t("noidPortfolio"));
        return;
    } else if ((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue === 0) {
        toast.warning(t("noprice"));
        return;
    } else if (performance.priceType === "range" && performance.priceCeil === 0) {
        toast.warning(t("noprice"));
        return;
    }
    const datas = {
        banner: `/api/public/pictures/${performance?.banner?.id}`,
        title: performance.title,
        portfolio: `/api/public/portfolios/${portfolio.id}`,
        bannerOffset: performance.bannerOffset,
        ...(performance.description !== "" && {description: performance.description}),
        ...(performance.priceType !== "" && {priceType: performance.priceType}),
        ...((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue !== 0 && {priceValue: performance.priceValue}),
        ...(performance.priceType === "range" && performance.priceCeil !== 0 && {priceCeil: performance.priceCeil}),
        ...(performance.type !== "" && {type: `/api/service-types/${performance.type}`}),
        ...(performance.pictures.length > 0 && {pictures: performance.pictures.map((picture, index:number) => {return {
            picture: `/api/public/pictures/${picture.id}`,
            index: index
        }})}),
        ...(performance.galleries.length > 0 && {galleries: performance.galleries.map((gallery: { gallery: string; index: number; }, index:number) => {return {
            gallery: `/api/public/galleries/${gallery.gallery}`,
            index: index
        }})}),
        ...(performance.keywords.length > 0 && {keywords: performance.keywords.map((tag: string, index:number) => {return {
            customLabel: tag,
            index: index
        }})})
    }

    const response = await ServiceAPI.create(datas);
    if (response.status === 200 || response.status === 201) {
        toast.success(t("successService"));
        const getAllServcies = await PortfolioAPI.getServicesDraft(portfolio.id);
        if (getAllServcies.status === 200 || getAllServcies.status === 201) {
            dispatch(updateServices(getAllServcies.body));
            dispatch(updateResetPerformance())
        } else {
            handleError(getAllServcies);
        }
    } else {
        handleError(response);
    }
}