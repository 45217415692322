import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Radio, Textarea, Typography } from "@material-tailwind/react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Storage } from "../../../../services/storage";
import { ContactCustomStyle } from "../../../../components/CustomStyles/ContactCustomStyle";

import Select from "react-select";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import CardUserForm from "../../../../components/Box/CardUserForm";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import ContactsAPI from "../../../../services/API/Clients/ContactAPI";
import InputPlace from "../../../../components/Form/Input/InputPlace";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../../../services/redux/store";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Appointment() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const [listOfServices, setListOfServices] = useState<{ value: string, label: string}[]>([]);
    const general = useSelector((state: RootState) => state.general);
    const portfolio = useSelector((state: RootState) => state.form);
    const [uuid, setUuid] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [myId, setMyId] = React.useState<string>("");
    const [data, setData] = React.useState<{
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    }>({
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        place: "",
        phone: "",
        email: "",
    });
    const [dataForm, setDataForm] = React.useState<{
        prestation: string;
        times: { begin: string; end: string }[];
        kindPlace: string;
        place: string;
        message: string;
    }>({
        prestation: "",
        times: [{ begin: "", end: "" }],
        kindPlace: "",
        place: "",
        message: "",
    });

    const fetchMyID = async () => {
        const iHaveToken = Storage.getToken();
        if (iHaveToken) {
            if (!general?.me) return;
            setMyId(general?.me?.portfolio?.id);
            setData({
                firstname: general?.me?.firstname,
                lastname: general?.me?.lastname,
                email: general?.me?.email,
                plan: general?.me?.plan,
            });
            if (general?.me?.company) setData({ ...data, company: general?.me?.company });
            if (general?.me?.country) setData({ ...data, country: general?.me?.country?.code });
            if (general?.me?.place) setData({ ...data, place: general?.me?.place?.id });
            if (general?.me?.phone) setData({ ...data, phone: general?.me?.phone });
        }
        if (!id) return;
        const response = await PortfolioAPI.portfolio(id);
        if (response.status === 200) {
            setName(response.body.name);
        } else {
            handleError(response);
        }
    };

    useEffect(() => {
        fetchMyID();
    }, []);

    const handleAddItem = () => {
        setDataForm({ ...dataForm, times: [...dataForm.times, { begin: "", end: "" }] });
    };

    const handleInputChange = (index: number, field: "begin" | "end", value: string) => {
        const newDatas = [...dataForm.times];
        newDatas[index][field] = value;
        setDataForm({ ...dataForm, times: newDatas });
    };

    const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeDataPhone = (value: string) => {
        setData({ ...data, phone: value });
    };

    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataForm({ ...dataForm, kindPlace: e.target.value });
    };

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDataForm({ ...dataForm, message: e.target.value });
    };

    const handleSubmit = async () => {
        const iHaveToken = Storage.getToken();

        if (dataForm.prestation === "" || dataForm.prestation === undefined) {
            toast.warning(t("missingDataPrestation"));
        } else if (dataForm.times[0].begin === "" || dataForm.times[0].begin === undefined) {
            toast.warning(t("missingDataBegin"));
        } else if (dataForm.times[0].end === "" || dataForm.times[0].end === undefined) {
            toast.warning(t("missingDataEnd"));
        } else if (dataForm.message === "" || dataForm.message === undefined) {
            toast.warning(t("missingDataMessage"));
        } else if (dataForm.kindPlace === "" || dataForm.kindPlace === undefined) {
            toast.warning(t("missingDataKindPlace"));
        } else if (dataForm.kindPlace === "customadress" && (dataForm.place === "" || dataForm.place === undefined)) {
            toast.warning(t("missingDataPlace"));
        } else if (!general?.me && (data.firstname === "" || data.firstname === undefined)) {
            toast.warning(t("missingFirstname"));
        } else if (!general?.me && (data.lastname === "" || data.lastname === undefined)) {
            toast.warning(t("missingLastname"));
        } else if (!general?.me && (data.email === "" || data.email === undefined)) {
            toast.warning(t("missingEmail"));
        } else {
            const datas: {
                sender?: {
                    company?: string;
                    firstname?: string;
                    lastname?: string;
                    country?: string;
                    place?: string;
                    phone?: string;
                    email?: string;
                };
                message: string;
                service: string;
                dates: { begin: string; end: string }[];
                place?: string;
                type: string;
                receiver: string;
            } = {
                message: dataForm.message,
                service: `/api/public/services/${dataForm.prestation}`,
                dates: dataForm.times
                    .map((time) => {
                        if (time.begin === undefined || time.end === undefined || time.begin === "" || time.end === "") return;
                        return { begin: moment(time.begin).format(), end: moment(time.end).format() };
                    })
                    .filter((date) => date !== undefined) as { begin: string; end: string; }[],
                type: "appointment",
                place: dataForm.kindPlace === "todefinelater" ? undefined : dataForm.kindPlace === "customadress" ? `/api/public/places/${dataForm?.place}` : (`/api/public/places/${general?.me?.place?.id ?? ""}`),
                receiver: `/api/public/portfolios/${id}`,
                sender: {}
            };
            if (!iHaveToken) {
                if (!data.firstname || data.firstname === "") {
                    toast.warning(t("missingDataFirstname"));
                    return;
                }
                if (!data.lastname || data.lastname === "") {
                    toast.warning(t("missingDataLastname"));
                    return;
                }
                if (!data.email || data.email === "") {
                    toast.warning(t("missingDataEmail"));
                    return;
                }
                datas.sender = {
                    ...(data.company && { company: data.company }),
                    firstname: data.firstname,
                    lastname: data.lastname,
                    ...(data.country && { country: `/api/public/countries/${data.country}` }),
                    ...(data.place && { place: `/api/public/places/${data.place}` }),
                    ...(data.phone && { phone: data.phone }),
                    email: data.email
                };
            }
            const response = await ContactsAPI.newService(datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("message_sent"));
                setDataForm({
                    prestation: "",
                    times: [{ begin: "", end: "" }],
                    kindPlace: "",
                    place: "",
                    message: "",
                });
            } else handleError(response);
        }
    };

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    useEffect(() => {
        if (portfolio?.services) {
            const services = portfolio.services.data.map((service: any) => {
                return { value: service.id, label: service.title };
            });
            setListOfServices(services);
        }
    }, [portfolio]);

    const couldIContact = portfolio?.features?.contact && !portfolio?.features?.actions?.contact;

    return (
        <div className="mx-auto px-4 py-6 flex flex-col md:flex-row flex-wrap w-full md:w-2/3">
            <div className="w-full flex flex-row items-center justify-between mb-4">
                {portfolio?.features?.contact &&
                !(
                    !portfolio?.features?.contact?.offer &&
                    portfolio?.features?.contact?.appointment &&
                    !portfolio?.features?.contact?.buy &&
                    !portfolio?.features?.contact?.contact
                ) ? (
                    <FontAwesomeIcon icon="arrow-left" onClick={() => navigate(portfolio?.publicLink?.startsWith("/portfolio") ? `${portfolio.publicLink}/contact` : `/portfolio/${portfolio.id}/contact`)} className="text-purple-500 mr-4 cursor-pointer" />
                ) : (
                    <div></div>
                )}
                <Typography className="text-2xl font-bold text-center">{t("takerdvwith", { name:  portfolio.name })}</Typography>
                <div></div>
            </div>
            {myId === id && <div className="flex flex-col gap-4 my-4 px-4 py-2 rounded-lg bg-gray-100 w-full">{t("n_contact_own_portfolio")}</div>}
            <CardUserForm disabled={couldIContact} myId={myId} data={data} handleChangeData={handleChangeData} handleChangeDataPhone={handleChangeDataPhone} />
            <Typography className="text-lg font-bold text-left w-full mb-2">{t("wantedoffer")}</Typography>
            <Select
                placeholder={t("performance")}
                name={t("performance")}
                className="w-full relative top-0"
                options={listOfServices}
                styles={ContactCustomStyle}
                menuPortalTarget={document.body}
                onChange={(e) => e && setDataForm({ ...dataForm, prestation: e.value })}
                menuPosition="fixed"
                isDisabled={couldIContact}
            />
            <Typography className="text-lg font-bold text-left w-full mt-4 mb-2">{t("perfdate")}</Typography>
            <div className="flex flex-col w-full gap-4 mb-2 mt-2">
                {dataForm.times.map((data, index) => (
                    <div key={data.begin + data.end} className="flex flex-col md:flex-row w-full gap-4 mb-2 mt-2">
                        <Input
                            type="datetime-local"
                            color="purple"
                            name={`begin${index}`}
                            label={t("begin")}
                            className="w-full bg-white"
                            containerProps={{ className: "min-w-[72px]" }}
                            onChange={(e) => handleInputChange(index, "begin", e.target.value)}
                            disabled={couldIContact}
                            value={data.begin}
                        />
                        <Input
                            type="datetime-local"
                            color="purple"
                            name={`end${index}`}
                            label={t("end")}
                            className="w-full bg-white"
                            containerProps={{ className: "min-w-[72px]" }}
                            onChange={(e) => handleInputChange(index, "end", e.target.value)}
                            disabled={couldIContact}
                            value={data.end}
                        />
                    </div>
                ))}
            </div>
            <button className="w-fit mx-auto mt-2" onClick={handleAddItem}>
                <GradientButton text="+" disabled={couldIContact} />
            </button>
            <Typography className="text-lg font-bold text-left w-full mt-4 ">{t("perfplace")}</Typography>
            <div className="flex flex-col md:flex-row w-full gap-0 md:gap-4 ">
                <Radio name="kindPlace" label={t("todefinelater")} value="todefinelater" className="bg-white" onChange={handleChangeRadio} color="purple" disabled={couldIContact} />
                <Radio name="kindPlace" label={t("customadress")} value="customadress" className="bg-white" onChange={handleChangeRadio} color="purple" disabled={couldIContact} />
                <Radio name="kindPlace" label={t("athome")} value="athome" className="bg-white" onChange={handleChangeRadio} color="purple" disabled={couldIContact || (!general || !general.me || !general.me.place)} />
            </div>
            {dataForm.kindPlace === "customadress" && (
                <div className="w-full mt-4">
                    <InputPlace uuid={uuid} onSelect={(place) => setDataForm({ ...dataForm, place })} disabled={couldIContact} title="prestaPlace" />
                </div>
            )}
            <Typography className="text-lg font-bold text-left w-full mt-4 mb-3">{t("messageoffer")}</Typography>
            <Textarea maxLength={5000} color="purple" label={t("description")} className="w-full bg-white" disabled={couldIContact} onChange={handleChangeTextarea} />
            <button className="w-fit mx-auto mt-4" onClick={handleSubmit}>
                <GradientButton text={t("send")} disabled={couldIContact} />
            </button>
        </div>
    );
}
