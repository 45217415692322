import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Storage } from "../../services/storage";
import { HashLink } from 'react-router-hash-link';

export default function Premium({ monthly, isMiddle = true }: Readonly<{ monthly: boolean; isMiddle?: boolean }>) {
    const { t } = useTranslation();
    const currentPage = window.location.pathname;

    const isLogin = Storage.getToken();

    return (
        <div className={`w-full ${isMiddle ? "md:w-[48%]" : "md:w-full mx-auto"} max-w-[400px] bg-gray-100 rounded-lg py-4 relative`}>
            <div className="absolute -top-5 left-1/2 -translate-x-1/2 w-fit bg-white border border-black text-black text-center rounded-lg px-4 py-2">
                <span className="text-base flex gap-2 items-center">
                    <FontAwesomeIcon icon="crown" />
                    {t("PREMIUM")}
                </span>
            </div>
            <div className="text-center flex flex-col mt-3 px-4">
                {!monthly ? (
                    <>
                        <span className="text-base font-bold text-orange-500">
                            {t("chf")} 6.00 {t("pricesttcMonthly")}
                        </span>
                        <span className="text-xs font-bold text-gray-800">
                            {t("orYearly")} {t("chf")} 72.00 {t("pricesttcYearly")}
                        </span>
                        <div className="px-2 py-1 bg-orange-500 w-fit mx-auto text-xs text-white mt-4 rounded-lg">{t("threeMonthsOffers")}</div>
                    </>
                ) : (
                    <span className="text-base font-bold text-orange-500">
                        {t("chf")} 8.00 {t("pricesttcMonthly")}
                    </span>
                )}
            </div>
            <div className="text-center flex flex-col mt-2 px-4 border-t-4 border-b-4 border-t-white border-b-white py-4"><span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <FontAwesomeIcon icon="infinity" className="text-orange-500 text-2xl" />
                    </span>
                    {t("unlimitedUploads")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <FontAwesomeIcon icon="rocket" className="text-orange-500 text-2xl" />
                    </span>
                    {t("visibilityBoosted")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <FontAwesomeIcon icon="globe" className="text-orange-500 text-2xl" />
                    </span>
                    {t("subdomainePerso")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <FontAwesomeIcon icon="chart-column" className="text-orange-500 text-2xl" />
                    </span>
                    {t("statistic")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <FontAwesomeIcon icon="headset" className="text-orange-500 text-2xl" />
                    </span>
                    {t("prioritaryAssistance")}
                </span>
                <HashLink
                    to="/tarifs#detailTarifs"
                    className="text-sm font-bold text-orange-500 hover:text-orange-100 transition-all mx-auto"
                >
                    {t("detail")}
                </HashLink>
            </div>
            <div className="text-center flex flex-col mt-2 px-4 pt-3">
                {currentPage !== "/subscription/premium" && (
                    <Link
                        to={isLogin ? "/subscription/premium" : "/signup?redirect=premium"}
                        className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-200 transition-all w-fit mx-auto mb-2"
                        dangerouslySetInnerHTML={{ __html: t("obtainPremium") }}
                    ></Link>
                )}
                <span className="flex gap-2 text-xs mx-auto" dangerouslySetInnerHTML={{ __html: t("renewSubscription") }}></span>
            </div>
        </div>
    );
}
