import React from "react";
import { useTranslation } from "react-i18next";

export default function GradientButtonButton({ text, disabled = false, loading = false }: { text: string, disabled?: boolean, loading?: boolean }) {
    const { t } = useTranslation();

    return (
        <button
            className={`${disabled ? "border-2 border-transparent bg-gray-300 text-white" : "border-2 bg-orange-500 hover:bg-orange-200 border-transparent text-white hover:text-black"} inline w-full py-2 px-4 rounded-full flex text-center items-center justify-center`}
            style={{
                transition: "all .25s ease-in-out",
            }}
            disabled={disabled}
            type="button"
        >
            {loading ?
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
                    </svg>
                :
                    <span className="flex text-center">{t(text)}</span>
            }
        </button>
    );
}
